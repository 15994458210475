<template src="./session-history.html"></template>
<script>
import appHeader from ".././../../../shared-module/components/app-header/app-header.vue";
import sessionService from "../../../services/session-service";
import validatorService from "../../../services/validator-service";
import quizService from "../../../services/quiz-service";
import moment from "moment";
var chevronListBox;

export default {
  name: "app-session-history",
  components: {
    "app-header": appHeader,
  },
  data: function () {
    return {
      chevronListBox,
      createSessionFlag: false,
      popupType: 1,
      popup: false,
      quizId: "",
      sessionName: "",
      sessionDescription: "",
      sessionId: "",
      startDate: "",
      endDate: "",
      instructorId: "",
      sessionsInfo: "",
      sessions: "",
      date: "",
      quizName: "",
      moduelId: "",
      courseId: "",
      sessionDesc: "",
      newSession: false,
      sessionDescriptionPopup: false,
    };
  },
  mounted() {
    this.quizId = this.$route.query.quizId;
    this.getCurrentSessions();
  },
  methods: {
    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    closePopup() {
      this.sessionId = "";
      this.sessionName = "";
      this.sessionDescription = "";
      this.startDate = "";
      this.endDate = "";
      this.createSessionFlag = false;
      this.newSession = false;
      this.sessionDescriptionPopup = false;
    },
    createSessionHandler(currentSession, res) {
      if (res == 1) {
        this.newSession = true;
        this.createSessionFlag = true;
      } else {
        this.sessionId = currentSession._id;
        this.sessionName = currentSession.name;
        this.sessionDescription = currentSession.description;
        this.startDate = moment(currentSession.start_time).format(
          "YYYY-MM-DDThh:mm"
        );
        this.endDate = moment(currentSession.end_time).format(
          "YYYY-MM-DDThh:mm"
        );
        this.createSessionFlag = true;
      }
    },
    publishSession() {
      if (this.newSession) {
        this.addNewSession();
      } else {
        this.updateSession();
      }
    },
    async updateSession() {
      this.func = true;
      let sessionData = {
        name: this.sessionName,
        description: this.sessionDescription,
        start_time: this.startDate,
        end_time: this.endDate,
        sessionId: this.sessionId,
      };
      const response = await sessionService.editSession(sessionData);
      if (response) {
        this.createSessionFlag = false;
        this.$toast.success("Session updated Successfully");
      }
    },
    async addNewSession() {
      try {
        let sessionData = {
          quiz_id: this.quizId,
          name: this.sessionName,
          description: this.sessionDescription,
          start_time: this.startDate,
          end_time: this.endDate,
          instructor_id: this.instructorId,
          module_id: this.moduelId,
          course_id: this.courseId,
        };
        const sessionFields = ["name", "description", "end_time", "start_time"];
        let isSessionDataValidated = await validatorService.validate(
          sessionFields,
          sessionData
        );
        if (isSessionDataValidated) {
          throw new Error(isSessionDataValidated);
        }
        const response = await sessionService.createSession(sessionData);
        if (response) {
          if (response.data.status === "success") {
            this.$toast.success("Session Created Successfully");
            this.createSessionFlag = false;
          } else {
            this.$toast.warning(response.data.message);
          }
        }
        this.getCurrentSessions();
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async getCurrentSessions() {
      try {
        let response;
        response = await quizService.getSessionsByQuizId(this.quizId);
        if (response) {
          this.sessionsInfo = response.data.data.sessions;
          this.date = response.data.data.sessions[0].quiz_id.created_at;
          this.quizName = response.data.data.sessions[0].quiz_id.name;
          this.instructorId = response.data.data.sessions[0].instructor_id;
          this.moduelId = response.data.data.sessions[0].module_id;
          this.courseId = response.data.data.sessions[0].course_id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    sessionDescriptionText(desc) {
      this.sessionDesc = desc
      this.sessionDescriptionPopup = true;
    },
  },
};
</script>
<style src="./session-history.css" scoped></style>
