<template src="./edit-quiz-single-question.html"></template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import quizService from "../../../services/quiz-service";
import tagService from "../../../services/tag-service";
import storageService from "../../../services/storage-service";
import draggable from "vuedraggable";

function Stack() {
  this.stac = new Array();
  this.pop = function () {
    return this.stac.pop();
  };
  this.push = function (item) {
    this.stac.push(item);
  };
}
var chevronListBox;
var windowType = 1;
var stack = new Stack();
export default {
  name: "app-edit-quiz-single-question",
  components: {
    VueTagsInput,
    draggable,
  },
  data: function () {
    return {
      alphabets: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
      question_types: [
        {
          id: 'fill_in_the_blank',
          name: 'Fill in the blank'
        },
        {
          id: 'mix_and_match',
          name: 'Mix and match'
        },
        {
          id: 'quiz',
          name: 'Quiz'
        },
        {
          id: 'drag_and_drop',
          name: 'Drag and drop'
        }
      ],
      chevronListBox,
      windowType,
      stack,
      tag: "",
      selectedTags: [],
      difficulty_level: "",
      ai_config: {
        content: 'check Content of answer and give marks out of 100 in content_marks field',
        understanding: 'check Understanding of answer and give marks out of 100 in understanding_marks field',
        writing_skill: 'check Writing Skill of answer and give marks out of 100 in writing_skills_marks field',
        evidence: 'check Evidence of answer and give marks out of 100 in evidence_marks field',
        organization: 'check Organization of answer and give marks out of 100 in organization_marks field',
      },
      errors: [],
      ommissions: [],
      DAQ: false,
    };
  },
  watch: {
    question: {
      handler(question) {
        this.$parent.updateLocalStorage();
        this.validateQuestion();
      },
      deep: true,
    },
    index(val, prevVal) {
      this.selectedTags = [];
      if (this.question.related_video_id) {
        if (this.videos && this.videos.length) {
          this.videos.forEach((video) => {
            if (video.id == this.question.related_video_id) {
              this.question.related_video = video;
            }
          });
        }
      }
      if (this.question.tags && this.question.tags.length) {
        this.question.tags.forEach((elm) => {
          if (elm.tag_type === "general") {
            this.selectedTags.push({ id: elm._id, text: elm.tag_name });
          } else {
            this.difficulty_level = elm.tag_name;
          }
        });
      }
      if (this.question.difficulty_level) {
        this.difficulty_level = this.question.difficulty_level;
        this.onDifficultyLevelChange(this.question.difficulty_level);
      }
      this.validateQuestion();
    },
  },
  props: {
    index: {
      type: String,
    },
    question: {
      type: Object,
    },
    videos: {
      type: Array,
    },
    sections: {
      type: Array,
    },
    paragraphs: {
      type: Array,
    },
    tags: {
      type: Array,
    },
    key: {
      type: Number,
    },
    quizType: {
      type: String,
    },
    ai: {
      type: Boolean,
    },
  },
  computed: {
    filteredItems() {
      return this.tags.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  mounted() {
    if (this.question.question.startsWith("<")) {
      this.question.question = this.question.question_and_equation;
    }
    
    if(!this.question.ai_config) {
      this.question.ai_config = {
        content: true,
        understanding: true,
        writing_skill: true,
        evidence: true,
        organization: true,
      };
    }
    if(!this.question.ai_prompt_checking) {
      this.question.ai_prompt_checking = 'check answer of question and give marks out of 100 in marks_obtained field';
    }
    if(!this.question.ai_evaluation_prompt) {
      this.question.ai_evaluation_prompt = 'give evaluation result or any suggestions for improvement in evaluation_result field in text';
    }
    if(!this.question.ai_content_prompt) {
      this.question.ai_content_prompt = 'check Content of answer and give marks out of 100 in content_marks field';
    }
    if(!this.question.ai_understanding_prompt) {
      this.question.ai_understanding_prompt = 'check Understanding of answer and give marks out of 100 in understanding_marks field';
    }
    if(!this.question.ai_writting_skill_prompt) {
      this.question.ai_writting_skill_prompt = 'check Writing Skill of answer and give marks out of 100 in writing_skills_marks field';
    }
    if(!this.question.ai_evidence_prompt) {
      this.question.ai_evidence_prompt = 'check Evidence of answer and give marks out of 100 in evidence_marks field';
    }
    if(!this.question.ai_organization_prompt) {
      this.question.ai_organization_prompt = 'check Organization of answer and give marks out of 100 in organization_marks field';
    }
    if(!this.question.ai_evaluation_prompt_chain || this.question.ai_evaluation_prompt_chain.length === 0) {
      this.question.ai_evaluation_prompt_chain = [{value: this.question.ai_evaluation_prompt}];
    }
    this.selectedTags = [];
    if (this.question.related_video_id) {
      if (this.videos && this.videos.length) {
        this.videos.forEach((video) => {
          if (video.id == this.question.related_video_id) {
            this.question.related_video = video;
          }
        });
      }
    }
    if (this.question.tags && this.question.tags.length) {
      this.question.tags.forEach((elm) => {
        if (elm.tag_type === "general") {
          if(this.ai !== 'true') {
            this.selectedTags.push({ id: elm._id, text: elm.tag_name });
          }
        } else {
          this.difficulty_level = elm.tag_name;
        }
      });
    }
    if ((this.ai === true || this.ai === 'true') && this.question.ai_topic) {
      console.log(this.question)
      this.selectedTags.push({ id: this.question.ai_topic._id, text: this.question.ai_topic.name });
    }
    if (this.question.difficulty_level) {
      this.difficulty_level = this.question.difficulty_level;
      this.onDifficultyLevelChange(this.question.difficulty_level);
    }
    this.validateQuestion();
  },
  methods: {
    addNewPrompt() {
      const newPrompt = '';
      this.question.ai_evaluation_prompt_chain.push({ value: newPrompt });
      this.$forceUpdate();
    },
    deletePrompt(index) {
      this.question.ai_evaluation_prompt_chain.splice(index, 1);
      this.$forceUpdate();
    },
    updatePromptConfig() {
      this.$forceUpdate();
    },
    onDifficultyLevelChange() {
      let currrent = this.question.tags.filter((t) => t.tag_type === "level");
      currrent = currrent.length ? currrent[0] : false;
      if (!currrent || currrent.tag_name !== this.difficulty_level)
        this.saveNewTags(this.selectedTags);
    },
    onChange(event) {
      this.videos.forEach((video, index) => {
        if (video.id.toString() === event.target.value) {
          this.question.related_video = video;
        }
      });
      this.$parent.updateLocalStorage();
      this.validateQuestion();
    },
    onChangeType(event) {
      this.question.type = event.target.value;
      this.validateQuestion();
      this.$parent.updateLocalStorage();
    },
    markTrueOrFalse(optionIndex) {
      optionIndex = parseInt(optionIndex, 10);
      this.question.options[optionIndex].is_correct = true;
      if (this.question.options[optionIndex].is_correct) {
        this.question.options.forEach((option, index) => {
          if (index !== optionIndex) {
            this.question.options[index].is_correct = false;
          }
        });
      }
      this.$parent.updateLocalStorage();
      this.validateQuestion();
    },
    imgPlaceholder(e) {
      e.target.src = "../img/icons/Group 1572.svg";
    },
    moveQuestionUp() {
      this.$parent.moveQuestionUp(this.index);
    },
    moveQuestionDown() {
      this.$parent.moveQuestionDown(this.index);
    },
    deleteQuestion() {
      this.$toast.warning("You are going to delete this question");
      this.$parent.deleteQuestion(this.index);
    },
    async saveNewTags(newTags) {
      try {
        this.selectedTags = newTags;
        if (this.selectedTags.length || this.difficulty_level) {
          let allTags = [];
          if (this.difficulty_level) {
            allTags.push({
              tag_name: this.difficulty_level,
              tag_type: "level",
            });
          }
          if(this.selectedTags.length > 0) {
            this.selectedTags.forEach((element) => {
              allTags.push({ tag_name: element.text, tag_type: "general" });
            });
          }
          if (allTags.length) {
            let res = await tagService.saveTags({ tags: allTags });
            if (res) {
              this.question.tags = res.data.data.tags;
              this.question.difficulty_level = this.difficulty_level;
              this.$parent.updateLocalStorage();
              this.validateQuestion();
            }
          } else {
            this.question.tags = allTags;
          }
        } else {
          this.question.tags = [];
          this.$parent.updateLocalStorage();
          this.validateQuestion();
        }
      } catch (error) {
        console.log("error", error);
        this.$toast.error(error.response.data.message);
      }
    },
    async fileChooser(type, indx) {
      try {
        const userId = storageService.get("uid");
        var input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async (e) => {
          if (parseInt(e.target.files.length) > 1) {
            this.$toast.warning("You can only upload a single image file");
          } else {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("userId", userId);
            try {
              let res = await quizService.uploadImage(formData);
              if (res) {
                if (type === "question") {
                  this.question.image_url = res.data.link;
                } else if (type === "solution") {
                  this.question.solution_image_url = res.data.link;
                } else {
                  this.question.options[indx].image_url = res.data.link;
                }
              }
            } catch (error) {
              console.log("error", error);
              this.$toast.error(error.response.data.message);
            }
          }
        };
        input.click();
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    assignWeightage() {
      if (this.question.weightage) {
        this.question.self_assigned_weightage = true;
      } else {
        this.question.self_assigned_weightage = false;
      }
    },
    addOption() {
      if (this.question.options.length < 8)
        this.question.options.push({ ...this.$parent.question.options[0] });
    },
    addSolution() {
      if (this.question.solution)
        this.question.solution.push({ ...this.$parent.question.solution[0] });
    },
    removeOption(index) {
      if (this.question.options.length > 2) {
        this.question.options.splice(index, 1);
      }
    },

    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    firstWindowHandler(type) {
      this.windowType = type;
    },
    equationToggle(id) {
      this.$toast.warning(
        "Warning! Switching text area will erase your written text"
      );
      if (id.includes("option")) {
        let value = id.split("-");
        if (this.question.options[value[0]].equation) {
          this.question.options[value[0]].equation = "";
        } else {
          this.question.options[value[0]].equation = " ";
        }
      } else {
        if (this.question[id]) {
          this.question[id] = "";
        } else {
          this.question[id] = " ";
        }
      }
      this.$parent.updateLocalStorage();
      this.validateQuestion();
    },
    mathCreator: function (question, id, data) {
      setTimeout(() => {
        let mathFieldSpan = document.getElementById(id);
        let MQ = MathQuill.getInterface(2);
        let mathField = MQ.MathField(mathFieldSpan, {
          spaceBehavesLikeTab: false, // configurable
          handlers: {
            edit: function () {
              if (id.includes("option")) {
                let value = id.split("-");
                question.options[value[1]].equation = mathField.latex();
              } else {
                let key = id.split("-");
                question[key[1]] = mathField.latex();
              }
            },
          },
        });
        if (mathField) {
          if (!data.match(/\\\s/g)) {
            data = data.replace(/\s+/g, "\\ ");
          }
          if (data !== "" && data !== "null") {
            mathField.latex(data);
            if (!mathField.latex()) {
              if (id.includes("option")) {
                let value = id.split("-");
                question.options[value[1]].equation = " ";
              } else {
                let key = id.split("-");
                question[key[1]] = " ";
              }
              this.validateQuestion();
            }
          }
        }
      }, 10);
    },
    handleMatchInput(event, option){
      option.correct_answer = event.target.value;
      this.validateQuestion();
    },
    validateQuestion() {
        try {
          let errors = [];
          let ommissions = [];
          let question = this.question;
          if (
            question.equation === undefined ||
            question.equation === "" ||
            question.equation === " " ||
            !question.equation.replace(`\\`, "").trim()
          ) {
            if (
              question.question === undefined ||
              question.question === "" ||
              question.question === " "
            ) {
              errors.push(`Question text can not be empty`);
            }
          }
          if(question.type === 'quiz' || question.type === 'drag_and_drop' || question.type === 'mix_and_match'){
            if (question.options && question.options.length) {
              let oneOptionIsCorrect = 0;
              question.options.forEach((option, indx) => {
                if(question.type === 'mix_and_match') option.is_correct = option.correct_answer;
                if (
                  option.equation === undefined ||
                  option.equation === "" ||
                  option.equation === " " ||
                  !option.equation.replace(`\\`, "").trim()
                ) {
                  if (
                    option.option === undefined ||
                    option.option === "" ||
                    option.option === " "
                  ) {
                    errors.push(`Option text can not be empty at option # ${indx}`);
                  }
                }
                if (option.is_correct == true) {
                  oneOptionIsCorrect += 1;
                }
                if (indx + 1 === question.options.length) {
                  if (oneOptionIsCorrect === 0 && question.type !== 'mix_and_match') {
                    errors.push(`Correct option is required`);
                  } else if (oneOptionIsCorrect > 1 && question.type !== 'mix_and_match') {
                    errors.push(`Only one option be marked as true`);
                  }
                }
              });
            }
            else errors.push(`Question does not have valid options`);
          }
          else if(question.type === 'fill_in_the_blank' && !question.question.includes('__BLANK__')) {
            errors.push(`Question must have a valid blank signature (__BLANK__)`);
          }
          else if((question.type === 'fill_in_the_blank') && !question.solution) {
            errors.push(`Question must have a valid solution`);
          }
          else if(question.type !== 'quiz' && question.type !== 'drag_and_drop' && question.type !== 'mix_and_match' && question.type !== 'fill_in_the_blank') errors.push(`Question must have a valid type`);
          if (question.tags && !question.tags.length) {
            ommissions.push(`Minimum one tag should be added`);
          }

          this.errors = errors;
          this.ommissions = ommissions;
          this.$parent.updateLocalStorage();
        } catch (error) {
          console.error(error);
        }
    },
  },
};
</script>
<style src="./edit-quiz-single-question.css" scoped></style>
