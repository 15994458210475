class ValidatorService {
  validate(fields, body) {
    return new Promise((resolve, reject) => {
      try {
        fields.forEach((element, index) => {
          if (
            !body[fields[index]] ||
            body[fields[index]] === "" ||
            body[fields[index]] === " "
          ) {
            reject(`${fields[index]} is required`);
          }
        });
        resolve(false);
      } catch (error) {
        reject(error.message);
      }
    });
  }
  validatorExtensive = (fields, body) => {
    return new Promise((resolve, reject) => {
      const errors = {};
      fields.forEach((field, index) => {
        if (!body[field] || body[field] === "" || body[field] === " ") {
          errors[field] = `${fields[index]} is required`;
        }
      });
      if (this.isEmpty(errors)) resolve(false);
      else reject(errors);
    });
  };
  isEmpty = (value) => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  };
}
export default new ValidatorService();
