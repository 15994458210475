import AppQuiz from "./quiz.vue";
import AppQuizLanding from "./components/quiz-landing/quiz-landing.vue";
import AppEditQuizQuestion from "./components/edit-quiz-questions/edit-quiz-questions.vue";
import AppQuizStats from "./components/quiz-stats/quiz-stats.vue";
import sessionHistory from "./components/quiz-landing/session-history/session-history.vue";
import AppQuizResults from "./components/quiz-results/quiz-results.vue";
import AppIndividualResults from "./components/quiz-results/individual-results/individual-results.vue";
import AppIndividualResultsDaq from "./components/quiz-results/individual-daq-result/quiz-results.vue";
import AppGeneral from "./components/general/general.vue";
import AppQuestionViseStats from "./components/question-vise-stats/question-vise-stats.vue";
import AppPracticeQuiz from "./components/practice-quiz/practice-quiz.vue";

const quizRoutes = [
  {
    path: "/general",
    name: "general",
    component: AppGeneral,
  },
  {
    path: "/quiz/edit-quiz-questions",
    name: "edit-quiz-questions",
    component: AppEditQuizQuestion,
    props: true,
  },
  {
    path: "/quiz",
    component: AppQuiz,
    children: [
      {
        path: "quiz-landing",
        name: "app-quiz-landing",
        component: AppQuizLanding,
      },
      // {
      //   path: "edit-quiz-questions",
      //   name: "app-edit-quiz-questions",
      //   component: AppEditQuizQuestion,
      //   props: true,
      // },
      {
        path: "quiz-stats/:quiz_id",
        name: "app-quiz-stats",
        component: AppQuizStats,
      },
      {
        path: "quiz-stats/:quiz_id/:sessionid",
        name: "app-quiz-stats",
        component: AppQuizStats,
      },
      {
        path: "question-vise-stats/:quiz_id",
        name: "app-question-vise-stats",
        component: AppQuestionViseStats,
      },
      {
        path: "question-stats",
        name: "app-question-stats",
        component: AppEditQuizQuestion,
      },
      {
        path: "quiz-results",
        name: "app-quiz-results",
        component: AppQuizResults,
      },
      {
        path: "individual-results",
        name: "app-individual-results",
        component: AppIndividualResults,
      },
      {
        path: "individual-results-daq",
        name: "app-individual-results-daq",
        component: AppIndividualResultsDaq,
      },
      {
        path: "session-history",
        name: "app-session-history",
        component: sessionHistory,
      },
      {
        path: "",
        redirect: { name: "app-quiz-landing" },
      },
    ],
  },
  {
    path: "/practice-quiz",
    name: "practice-quiz",
    component: AppPracticeQuiz,
  },
];

export default quizRoutes;
