<template src="./question-vise-stats.html"></template>
<script>
import statsHeader from "./../stats-header/stats-header";
import SingleQuestionStat from "./../single-question-stat/single-question-stat";
import quizSummary from "./../../components/edit-quiz-questions/quiz-summary/quiz-summary";
import quizReportService from "../../services/quiz-report-service";
import quizService from '../../services/quiz-service';

export default {
  name: "app-question-vise-stats",
  components: {
    "app-stats-header": statsHeader,
    "app-single-question-stat": SingleQuestionStat,
    "app-quiz-summary": quizSummary,
  },
  data: function () {
    return {
      quiz_id: this.$route.params.quiz_id,
      questions: [],
      allOptions: {},
      quizSummary: false,
      totalAttemps: 0,
      quizName:"",
      quiz:[]
    };
  },
  methods: {
    quizSummaryHandler() {
      this.quizSummary = !this.quizSummary;
    },
    async scrol_to(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    navigateQuestion(id) {
    this.scrol_to(Number(id.split("-")[0]));
    },
    async getQuizResults(session) {
      let res = await quizService.getResultByQuizId(this.quiz_id);
      if (res.data && res.data.status === "success") {
        let questions = res.data.data.result.questions;
        this.totalAttemps = res.data.data.attemptCount;
        const questionStats = res.data.data.questionStats;
        questions.forEach(question => {
          _.findLast(questionStats.questionCorrectCount, correctQuestion => {
            if(correctQuestion._id == question.question_id._id) {
              question.correctCount = correctQuestion.correct_count;
              question.averageTime = correctQuestion.averageTime;
              question.bestTime = correctQuestion.bestTime;
            }
          });
          _.findLast(questionStats.questionMarkedCount, markedQuestion => {
            if(markedQuestion._id == question.question_id._id) {
              question.markedCount = markedQuestion.markedCount;
            }
          });
          _.findLast(questionStats.questionSkippedCount, skipQuestion => {
            if(skipQuestion._id == question.question_id._id) {
              question.skippedCount = skipQuestion.skippedCount;
            }
          });
        });

        questions = questions.map(question => {
          question = _.merge(question, question.question_id);
          delete question.question_id;
          return question;
        });

        console.log('questions')
        console.log(questions)
        this.questions = questions;

        this.$forceUpdate()
        
      }
    },
  },
  created() {
    this.getQuizResults();
  },
};
</script>
<style src="./question-vise-stats.css" scoped></style>
