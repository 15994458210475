<template src="./quiz-summary.html"></template>
<script>
export default {
  name: "app-quiz-summary",
  data: function () {
    return {
      search: "",
      questionsToDisplay: [],
      totalPages: 1,
      pageno: 1,
      showErrorDiv:false,
    };
  },
  props: {
    questions: {
      type: Array,
    },
    initPage: {
      type: Number,
    },
    errors: {
      type: Array,
    },
    ommissions: {
      type: Array,
    },
    reports: {
      type: Array,
    },
    quiz: {
      type: Object,
    },
  },
  mounted() {
    if (this.$route.name === "app-question-vise-stats") {
      this.showErrorDiv = false;
    } else {
      this.showErrorDiv = true;
    }
    this.totalPages =
      this.questions.length / 10 >= 1
        ? Math.ceil(this.questions.length / 10)
        : 1;
    if (this.questions.length >= 10) {
      this.questionsToDisplay = [...this.questions].splice(0, 10);
    } else {
      this.questionsToDisplay = [...this.questions].splice(
        0,
        this.questions.length
      );
    }
  },
  methods: {
    searchQuestion() {
      try {
        if (!this.search.trim()) {
          this.search = "";
          throw new Error("please enter a valid question nom in search box");
        }
        if (isNaN(Number(this.search.trim()))) {
          this.search = "";
          throw new Error(
            "only number should be entered in search box, character is not allowed"
          );
        }
        if (this.search.includes(".")) {
          this.search = "";
          throw new Error(
            "search should not contains decimal values, only integer is allowed"
          );
        }
        if (Number(this.search.trim()) <= 0) {
          this.search = "";
          throw new Error(
            "neagitive number and 0 is not allowed in search box"
          );
        }
        if (Number(this.search.trim()) > this.questions.length) {
          this.search = "";
          throw new Error("Invalid question no, question not found");
        }

        this.questionClicked(`${Number(this.search.trim()) - 1}-questions`);
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    chevronListBoxHandler() {
      // this.$emit("buttonClick", false);
    },
    changePage(pageNo) {
      this.questionSelected = false;
      this.pageno = pageNo;
      if (this.questions.length - (pageNo * 10 - 10) >= 10) {
        this.questionsToDisplay = [...this.questions].splice(
          pageNo * 10 - 10,
          10
        );
      } else {
        this.questionsToDisplay = [...this.questions].splice(
          pageNo * 10 - 10,
          this.questions.length - (pageNo * 10 - 10)
        );
      }
    },
    questionClicked(id) {
      this.$parent.navigateQuestion(id);
    },
    mathCreator: function (id, data) {
      let mathFieldSpan = document.getElementById(id);
      let MQ = MathQuill.getInterface(2);
      let mathField = MQ.MathField(mathFieldSpan, {
        spaceBehavesLikeTab: false,
        handlers: {
          edit: function () {},
        },
      });
      if (!data.match(/\\\s/g)) {
        data = data.replace(/\s+/g, "\\ ");
      }
      if (mathFieldSpan) {
        if (data !== "" && data !== "null") {
          mathField.latex(data);
        }
      }
    },
  },
};
</script>

<style src="./quiz-summary.css" scoped></style>
