<template src="./app-header.html"></template>

<script>
import ClickOutside from "vue-click-outside";
import storageService from "../../../quiz-module/services/storage-service";
import sessionService from "../../../quiz-module/services/session-service";
export default {
  name: "app-header",
  props: {
    method: { type: Function },
    page: { type: Function },
    quizId: { type: Function },
    quiz: { type: Function },
    reportCount: { type: Function },
  },
  data() {
    return {
      profile: false,
      userInfo: "",
      userDetails: "",
      report_count: "",
    };
  },
  methods: {
    goToHome(){
      this.$router.push('/quiz/quiz-landing'); 
    },
    openIt() {
      if (!this.profile) {
        this.profile = true;
      } else {
        this.profile = false;
      }
    },
    closeIt() {
      this.profile = false;
    },
    reportsPopupHandler() {
      window.open(`${process.env.VUE_APP_REPORTING_PANEL}quiz-issues`, '_blank')
    },
    logout() {
      storageService.set("uid", "");
      storageService.set("user", "");
      this.$router.push("/unauthorized-page");
      location.reload();
    },
    backMethod() {
      if (this.$route.query.editType) {
        this.$router.replace({ query: {} });
      }
      this.$parent.popupClose();
    },
    async getUserInfo() {
       try {
         this.userInfo = localStorage.getItem('user');
         let parsing = JSON.parse(this.userInfo);
         this.userDetails = parsing;
       } catch (error) {
         console.log(error);
       }
    
    },
    async reports() {
      if(this.quizId == undefined){
        return;
      }
    let response = await sessionService.getQuizSessions(this.quizId);
    this.report_count = response.data.data.sessions[0].quiz_id.reports_count;
  },
  },
  directives: {
    ClickOutside,
  },
  mounted() {
    this.getUserInfo();
    this.reports();
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
};
</script>

<style src="./app-header.css" scoped></style>
