<template src="./quiz-stats.html"></template>
<script>
import lodash from "lodash";
import appHeader from "../../../shared-module/components/app-header/app-header.vue";
import MapChart from "vue-map-chart";
import HorizontalBar from "../../../shared-module/charts/HorizontalBar";
import Bar from "../../../shared-module/charts/Bar";
import Doughnut from "../../../shared-module/charts/Doughnut";
import SingleQuestionStat from "./../single-question-stat/single-question-stat";
import quizReportService from "../../services/quiz-report-service";
import sessionService from "../../services/session-service";
import statsHeader from "../../components/stats-header/stats-header";
import VueJsonToCsv from "vue-json-to-csv";
import QuizService from "../../services/quiz-service";

// import { InfoControl, ReferenceChart, ChoroplethLayer } from "vue-choropleth";
// import { geojson } from "./map-data/py-departments-geojson";
// import paraguayGeojson from "./map-data/paraguay.json";
// import { pyDepartmentsData } from "./map-data/py-departments-data";

// import { LMap } from "vue2-leaflet";

import { LMap, LTileLayer, LMarker, LTooltip, LPopup } from "vue2-leaflet";

import L from "leaflet";

import "leaflet/dist/leaflet.css";

var chevronListBox;
var pageType = 1;

export default {
  name: "app-quiz-stats",
  components: {
    "app-header": appHeader,
    "app-HorizontalBar": HorizontalBar,
    "app-Doughnut": Doughnut,
    "app-Bar": Bar,
    "app-single-question-stat": SingleQuestionStat,
    "app-stats-header": statsHeader,
    "vue-json-to-csv": VueJsonToCsv,
    MapChart: MapChart,
    "l-map": LMap,
    "l-tile-layer": LTileLayer,
    "l-marker": LMarker,
    "l-tooltip": LTooltip,
    "l-popup": LPopup,
  },
  data: function () {
    return {
      quiz_id: this.$route.params.quiz_id,
      session_id: null,
      chevronListBox,
      allOptions: {},
      sessionValue: "all",
      dataa: "",
      singleQuizPopup: false,
      pageType,
      sessions: [],
      data: "",
      topStudents: [],
      performanceGraphLabels: [],
      performanceGraphDataset: [],
      tagsGraphLabels: [],
      tagsGraphDataset: [],
      quizStats: {},
      questionsStats: [],
      questionStatLoading: true,
      quiz: {},
      resultCount: 0,
      allTimeStats: true,
      quizSummary: false,
      question: "",
      countryStats: [],
      countryGraphLabels: [],
      countryGraphData: [],
      resultCohortsData: [],
      resultCohortsDataNew: [],
      resultCohortLabels: [],
      obj: [],
      toughestQuestion: [],
      namee: "",
      resultCohortsColors: [
        "#6A66F1",
        "#19CCFD",
        "#27FFCD",
        "#FFDA83",
        "#FF9100",
        "#FF6435",
        "#FF1E00",
      ],
      resultCohortsLevels: [
        {
          grade: "A+",
          detail: "Greate than 90%",
          key: "nintyplus",
        },
        {
          grade: "A",
          detail: "80% to 90%",
          key: "eightyToNinety",
        },
        {
          grade: "B",
          detail: "70% to 80%",
          key: "seventyToEighty",
        },
        {
          grade: "C",
          detail: "60% to 70%",
          key: "sixtyToSeventy",
        },
        {
          grade: "D",
          detail: "50% to 60%",
          key: "fiftyToSixty",
        },
        {
          grade: "E",
          detail: "40% to 50%",
          key: "fortytoFifty",
        },
        {
          grade: "F",
          detail: "Less than 40%",
          key: "lessThenForty",
        },
      ],
      tagsGraphData: [],
      classTagsGraphLabels: [],
      result: {},
      attemptDropDown: false,
      userWorldMap: true,
      userCountryMap: false,

      // center: { lat: 30.3753, lng: 69.3451 },
      // markers: [],
      // places: [],
      // currentPlace: null,

      // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // attribution:
      //   '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // zoom: 2,
      // center: [51.505, -0.159],
      // markerLatLng: [51.504, -0.159],

      // pyDepartmentsData,
      // paraguayGeojson,
      // colorScale: ["e7d090", "e9ae7b", "de7062"],
      // value: {
      //   key: "amount_w",
      //   metric: "% girls",
      // },
      // extraValues: [
      //   {
      //     key: "amount_m",
      //     metric: "% boys",
      //   },
      // ],
      // mapOptions: {
      //   attributionControl: false,
      // },
      // currentStrokeColor: "3d3213",
      // latLng: () => [0, 0],

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      // worldCenter: [47.31322, -1.319482],

      circle: {
        center: [47.41322, -1.0482],
        lahoreCenter: [31.5204, 74.3587],
        radius: 6,
        color: "red",
      },
      // cityWiseStats: [],
      isCityLoading: false,
      cityWiseStats: [],
      center: [30.3753, 69.3451],
      zoom: 1,
      markers: [],
      markerObjects: null,
      selectedRegion: "Pakistan",
      cordinatesHelper: {
        World: {
          center: [30.3753, 69.3451],
          zoom: 1,
        },
        Pakistan: {
          center: [30.3753, 69.3451],
          zoom: 5,
        },
        Punjab: {
          center: [31.1704, 72.7097],
          zoom: 7,
        },
        KPK: {
          center: [34.9526, 72.3311],
          zoom: 7,
        },
        Gilgit: {
          center: [35.8819, 74.4643],
          zoom: 7,
        },
        Blochistan: {
          center: [28.4907, 65.0958],
          zoom: 6,
        },
        Sindh: {
          center: [25.8943, 68.5247],
          zoom: 7,
        },
      },
      firstAttempt: true,
      firstAttemptTopStudents: [],
      latestAttemptTopStudents: [],
    };
  },
  // mounted() {
  //   this.geolocate();
  // },

  methods: {
    quizSummaryHandler() {
      this.quizSummary = !this.quizSummary;
    },
    async getQuizSessions() {
      const res = await sessionService.getQuizSessions(this.quiz_id);
      if (res.data && res.data.status === "success") {
        this.sessions = res.data.data.sessions;
        this.date = res.data.data.sessions[0].quiz_id.created_at;
        if (this.$route.params.sessionid) {
          this.sessionValue = this.$route.params.sessionid;
          this.changeSession({
            target: { value: this.$route.params.sessionid },
          });
        }
      }
    },
    singleQuizPopupHandler() {
      this.singleQuizPopup = !this.singleQuizPopup;
    },
    async getQuizTopStudents() {
      let [responseFirst, responseLatest] = await Promise.all([
        QuizService.getQuizTopStudents(
          this.quiz_id,
          null,
          true,
          this.session_id
        ),
        QuizService.getQuizTopStudents(
          this.quiz_id,
          null,
          false,
          this.session_id
        ),
      ]);
      if (responseFirst && responseLatest) {
        this.firstAttemptTopStudents = responseFirst.data.data.quiz;
        this.latestAttemptTopStudents = responseLatest.data.data.quiz;
        this.topStudents = this.firstAttempt
          ? this.firstAttemptTopStudents
          : this.latestAttemptTopStudents;
        this.topStudents.forEach((student, index) => {
          student.time_spent = this.formatTime(student.time_spent);
        });
      }
    },
    async getQuizGraphData() {
      let tagsData = {};
      const res = await quizReportService.getQuizResults(
        this.quiz_id,
        this.session_id
      );
      if (res.data && res.data.status === "success") {
        const quizAllResults = res.data.data.quizResults;
        quizAllResults.forEach((result, index) => {
          if (result.questions) {
            result.questions.forEach((question, index) => {
              if (question.tags) {
                question.tags.forEach((tag, index) => {
                  if (!tagsData[tag.tag_name]) {
                    tagsData[tag.tag_name] = { total: 0, correct: 0 };
                  }
                  tagsData[tag.tag_name].total =
                    tagsData[tag.tag_name].total + 1;
                  if (question.result) {
                    tagsData[tag.tag_name].correct =
                      tagsData[tag.tag_name].correct + 1;
                  }
                });
              }
            });
          }
        });
        this.tagsGraphLabels = [];
        this.tagsGraphDataset = [];
        this.tagsGraphLabels = Object.keys(tagsData);
        this.tagsGraphLabels.forEach((label, index) => {
          const percent =
            (tagsData[label].correct / tagsData[label].total) * 100;
          this.tagsGraphDataset.push(percent);
        });
        //this.topStudents = res.data.data.quiz;
      }
    },
    async getQuizStats() {
      const res = await quizReportService.getQuizStats(
        this.quiz_id,
        this.session_id
      );
      this.resultCohortsData = [];
      this.countryGraphLabels = [];
      this.countryGraphData = [];
      this.countryStats = [];
      if (res.data && res.data.status === "success") {
        this.quizStats = res.data.data.statData;
        if (res.data.data.quiz === null) {
          if (this.quizStats.averageTime === 0) {
            this.quizStats.averageTime;
          } else {
            this.quizStats.averageTime = this.formatTime(
              this.quizStats.averageTime
            );
          }
        } else {
          this.quiz = res.data.data.quiz;
          if (this.quizStats.averageTime === 0) {
            this.quizStats.averageTime;
          } else {
            this.quizStats.averageTime = this.formatTime(
              this.quizStats.averageTime
            );
          }
        }
        // console.log(resultCohortsData);
        const newData = [];
        const resultCohortsData = this.quizStats.gradeCounts;
        this.resultCohortsLevels.forEach((level, index) => {
          const data = [];
          for(let i = 0; i < this.resultCohortsLevels.length; i++){
            if(i!=index) data.push(null);
            else data.push((resultCohortsData[level.key] || 0).toFixed(2))
          }
          const d = {
            backgroundColor: this.resultCohortsColors[index],
            barThickness: 10,
            data,
            label: level.grade,
          };
          newData.push(d);
          this.resultCohortsData.push(resultCohortsData[level.key].toFixed(2));
        });
        this.resultCohortsDataNew = newData;
        // this.resultCohortLabels = this.resultCohortsLevels.map(l=>l.key);
        this.resultCohortLabels = ['A+', 'A', 'B', 'C', 'D', 'E', 'F'];
        if (this.quizStats.countryStats) {
          this.countryStats = this.quizStats.countryStats;
          this.quizStats.countryStats.forEach((country) => {
            this.countryGraphLabels.push(country._id);
            this.countryGraphData[country._id] = country.count;
          });
        }
      }
    },
    async getToughestQuestion() {
      try {
        let result_stats = [];
        // const res = await quizReportService.getQuizResults(this.quiz_id);
        this.getQuizResultsData();
        const res = await QuizService.getToughestQuestion(
          this.quiz_id,
          this.session_id
        );
        const data = res.data;
        this.toughestQuestion = data.data;
        this.questionStatLoading = false;
      } catch (error) {
        console.log("error occured while fetching quiz report -> ", error);
      }
    },
    async getQuizResultsData() {
      const res = await quizReportService.getQuizResults(this.quiz_id);
      this.resultCount = res.data.data.quizResults.length;
      let correct = 0;
      let incorect = 0;
      let obt_marks;
      let user_name;
      let total;
      let country;
      if (res.data.data.quizResults && res.data.status === "success") {
        this.namee = res.data.data.quizResults[0].quiz.name;
        const quizAllResults = res.data.data.quizResults;
        quizAllResults.forEach((result) => {
          if (result.questions) {
            result.questions.forEach((question) => {
              if (question.result) {
                correct = correct + 1;
              } else {
                incorect = incorect + 1;
              }
            });
          }
          user_name = result.user.full_name;
          obt_marks = result.obtained_marks;
          total = result.total_marks;
          country = result.country;
          let obj = {
            Quiz_Name: this.namee,
            User_Name: user_name,
            Correct_Questions: correct,
            Incorect_Questions: incorect,
            Obtain_Marks: obt_marks,
            Total: total,
            Country: country,
            Phone: result.user.uid_phone || '',
          };
          this.obj.push(obj);
          correct = 0;
          incorect = 0;
        });
      }
    },
    formatTime(sec) {
      let avgTime = "";
      let time = new Date(sec * 1000).toISOString().substr(11, 8);
      time = time.split(":");
      if (parseInt(time[0]) > 0) {
        avgTime = time[0] + "h ";
      }
      if (parseInt(time[1]) > 0) {
        avgTime = avgTime + time[1] + "m ";
      }
      if (parseInt(time[2]) > 0) {
        avgTime = avgTime + time[2] + "s";
      }
      return avgTime;
    },
    async changeSession(event) {
      this.session_id =
        event.target.value === "all" ? null : event.target.value;
      this.allTimeStats = event.target.value === "all";
      this.getQuizTopStudents();
      this.getQuizStats();
      if (!this.allTimeStats) {
        this.getQuizGraphData();
        this.calculateTagsGraphData();
      } else {
        this.getToughestQuestion();
      }
    },
    mathCreator: function (q, id, data) {
      try {
        setTimeout(() => {
          let mathFieldSpan = document.getElementById(id);
          let MQ = MathQuill.getInterface(2);
          let mathField = MQ.MathField(mathFieldSpan, {
            spaceBehavesLikeTab: false, // configurable
          });
          // console.log("id->", id);
          // console.log("mathFieldSpan->", mathFieldSpan);
          // console.log("mathField->", mathField);
          // console.log("data->", data);
          if (!data.match(/\\\s/g)) {
            data = data.replace(/\s+/g, "\\ ");
          }
          if (data !== "" && data !== "null") {
            if ((mathFieldSpan, mathField)) {
              mathField.latex(data);
            }
          }
        }, 500);
      } catch (err) {
        console.log("error occured in math quill rendring equation, ", err);
      }
    },
    async getResults() {
      const res = await QuizService.getQuizResults(this.quiz_id);
      if (res.data.data && res.data.status === "success") {
        const quizAllResults = res.data.data.quizResults;
        this.totalAttempts = quizAllResults.length;
        // this.calculateStats("class_average", quizAllResults);
      }
    },
    async calculateTagsGraphData() {
      try {
        const res = await QuizService.getTopicWiseStrength(this.session_id);
        if (res.data && res.data.status === "success") {
          let tagsGraphData = [];
          tagsGraphData.push({
            label: "Class Average",
            backgroundColor: "#7BC0F7",
            data: res.data.classAverageStats.map((a) => a.score_percentage),
            barThickness: 10,
          });
          this.classTagsGraphLabels = res.data.classAverageStats.map(
            (a) => a.name
          );
          this.tagsGraphData = tagsGraphData;
        }
      } catch (err) {
        console.log(err);
      }
    },
    attempField(e) {
      e.preventDefault();
      e.stopPropagation();
      this.attemptDropDown = !this.attemptDropDown;
    },
    userWorld() {
      this.center = this.cordinatesHelper.World.center;
      this.zoom = this.cordinatesHelper.World.zoom;
    },
    userCountry() {
      this.center = this.cordinatesHelper.Pakistan.center;
      this.zoom = this.cordinatesHelper.Pakistan.zoom;
    },
    async getCityWiseStats() {
      try {
        this.isCityLoading = true;
        const resp = await QuizService.getQuizCityWiseStats(this.quiz_id);
        this.isCityLoading = false;
        if (resp.data.status === "success") {
          this.cityWiseStats = resp.data.data.cityWiseStats;
        }

        if (resp.data.data.cordinatesList) {
          const formatedCordinates = resp.data.data.cordinatesList.map(
            (item, index) => {
              return {
                Id: index + 1,
                name: `${item.count}% person have attemped quiz from here`,
                position: { lat: item._id[0], lng: item._id[1] },
              };
            }
          );
          this.markers = formatedCordinates;
          this.$nextTick(() => {
            this.markerObjects = this.$refs.markersRef.map(
              (ref) => ref.mapObject
            );
          });
        }
      } catch (err) {
        this.isCityLoading = false;
        console.log(err);
      }
    },
    async userAttempts(filter) {
      if (filter == "first") {
        this.firstAttempt = true;
        this.topStudents = this.firstAttemptTopStudents;
      } else {
        this.firstAttempt = false;
        this.topStudents = this.latestAttemptTopStudents;
      }
    },
  },
  created() {
    L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.4/dist/images/";
    this.getQuizTopStudents();
    this.getQuizStats();
    this.getQuizSessions();
    this.getToughestQuestion();
    this.getResults();
    this.getCityWiseStats();
  },
};
</script>
<style src="./quiz-stats.css" scoped></style>
