<template src="./general.html"></template>
<script>
import quizService from "../../services/quiz-service";

export default {
  name: "app-general",
  components: {},
  data: () => {
    return {
      image_url: "",
      equation: "",
      equation_string: "",
      uploading: false,
    };
  },
  mounted() {
    this.mathCreator(this.equation);
  },
  methods: {
    imgPlaceholder(e) {
      e.target.src = "../img/icons/Group 1572.svg";
    },
    async fileChooser(type, indx) {
      try {
        this.image_url = "";
        var input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async (e) => {
          if (parseInt(e.target.files.length) > 1) {
            this.$toast.info("You can only upload a single image file");
          } else {
            var formData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("userId", "Instructor");
            try {
              this.uploading = true;
              let res = await quizService.uploadImage(formData);
              if (res) {
                this.uploading = false;
                this.image_url = res.data.link;
              }
            } catch (error) {
              console.log("error", error);
              this.$toast.error("Something went wrong while uploading image");
            }
          }
        };
        input.click();
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    copyToClipboard(field) {
      let data = "";
      if (field === "equation") {
        data = document.getElementById("equation_string").value;
      } else {
        data = this.image_url;
      }
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    mathCreator: function(data) {
      let mathFieldSpan = document.getElementById("equation");
      let MQ = MathQuill.getInterface(2);
      let mathField = MQ.MathField(mathFieldSpan, {
        spaceBehavesLikeTab: false,
        handlers: {
          edit: function() {
            document.getElementById(
              "equation_string"
            ).value = mathField.latex();
          },
        },
      });
      if (!data.match(/\\\s/g)) {
        data = data.replace(/\s+/g, "\\ ");
      }
      if (data !== "" && data !== "null") {
        mathField.latex(data);
      }
    },
  },
};
</script>

<style src="./general.css" scoped></style>
