import { render, staticRenderFns } from "./edit-quiz-questions.html?vue&type=template&id=c284e018&scoped=true&"
import script from "./edit-quiz-questions.vue?vue&type=script&lang=js&"
export * from "./edit-quiz-questions.vue?vue&type=script&lang=js&"
import style0 from "./edit-quiz-questions.css?vue&type=style&index=0&id=c284e018&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c284e018",
  null
  
)

export default component.exports