import api from "../services/api-service";
import axios from "axios";
import quizBackendService from "../../../environment/environment";

class QuizService {
  getVersion(body) {
    try {
      return api.post({
        url: "quiz/get-version",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizByType(body) {
    try {
      return api.post({
        url: "quiz/quiz-type",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getPublishQuiz(body) {
    try {
      return api.post({
        url: "quiz/get-publish",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }

  async updatePracticeQuestion(previous, current) {
    const body = {
      question: previous.question,
      options: previous.options,
      updatedQuestion: {
        question: current.question,
        solution: current.solution,
        options: current.options,
      },
    };

    try {
      return api.post({
        url: "practice/update-practice-reported-question",
        body,
      }); // Return the response data
    } catch (error) {
      throw new Error(error.response ? error.response.data : error.message); // Throw the error properly
    }
  }

  async getQuizUpdatedVersion(quiz_id) {
    try {
      return api.get({
        url: "quiz/get-quiz-updated-version/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getUnPublishQuiz(body) {
    try {
      return api.post({
        url: "quiz/get-unpublish",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  unpublishQuiz(body) {
    try {
      return api.put({
        url: "quiz/unpublish-quiz",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  // publishQuiz(body) {
  //   try {
  //     return api.put({
  //       url: "quiz/publish-quiz",
  //       body,
  //     });
  //   } catch (error) {
  //     return new Error(error);
  //   }
  // }
  archiveQuiz(body) {
    try {
      return api.put({
        url: "quiz/archive-quiz",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  unarchiveQuiz(body) {
    try {
      return api.put({
        url: "quiz/unarchive-quiz",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  searchQuiz(body) {
    try {
      return api.post({
        url: "quiz/search",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizQuestionCount(quiz_id) {
    try {
      return api.get({
        url: "quiz/get-quiz-questions/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getUnPublishedCount(instructorId) {
    try {
      return api.get({
        url: "quiz/get-unpublished-count/" + instructorId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getPublishedCount(instructorId) {
    try {
      return api.get({
        url: "quiz/get-published-count/" + instructorId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getSessionsByQuizId(quiz_id) {
    try {
      return api.get({
        url: "session/get-sessions-by-quiz-id/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizById(quiz_id) {
    try {
      return api.get({
        url: "quiz/get-quiz-for-instructor/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizzesByInstructorId(body) {
    try {
      return api.post({
        url: "quiz/get-quizzes-by-instructor-id",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getArchivedQuizzesByInstructorId(body) {
    try {
      return api.post({
        url: "quiz/get-archived-quizzes-by-instructor-id",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  createQuiz(body) {
    try {
      return api.post({
        url: "quiz/create-quiz",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  updateQuiz(body) {
    try {
      return api.put({
        url: "quiz/update-quiz",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getCoursesByInstructorId(id) {
    try {
      return api.get({
        url: "instructors/instructorUniversityList",
        params: {
          instructor_id: id,
        },
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getModulesByCourseId(id) {
    try {
      return api.get({
        url: "courses/getModulesByCourseId",
        params: {
          id: id,
        },
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getChapterByModuleId(id) {
    try {
      return api.get({
        url: "chapter/getChapterByModuleId",
        params: {
          id: id,
        },
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getAllfaculties() {
    try {
      return api.get({
        url: "faculty/get-all-faculties",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async createQuizFromCSV(body, config) {
    try {
      return api.post({
        url: "quiz/create-quiz-from-csv",
        body,
        config,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async uploadImage(body) {
    try {
      return api.post({
        url: "uploader/uploadImageToS3",
        body,
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async getAutoGeneratedQuiz(body) {
    try {
      return api.post({
        url: "quiz/create-automatic-quiz/",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async saveQuestions(body) {
    try {
      return api.post({
        url: "question/add-questions",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async createNewVersion(body) {
    try {
      return api.post({
        url: "question/create-new-version",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  duplicateQuiz(quiz_id) {
    try {
      return api.get({
        url: "quiz/get-quiz-data/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  delcsv(res) {
    try {
      return api.post({
        url: "quiz/send-data",
        body: { file: res },
      });
    } catch (error) {
      return new Error(error);
    }
  }
  downloadCsv(quiz_id) {
    try {
      return api.get({
        url: "quiz/find-questions/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }

  getShareableLink(quiz_id) {
    try {
      return api.get({
        url: "quiz/get-sharable-link/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResultsBySessionId(session_id) {
    try {
      return api.get({
        url: "result/get-results-by-session-id/" + session_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResultsByQuizId(quiz_id) {
    try {
      return api.get({
        url: "result/get-results-by-quiz-id/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResultsByResultIdForDaq(result_id) {
    try {
      return api.get({
        url: "result/get-single-daq-result-stats/" + result_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  updateResponse(id,question_id,text){
    try {
      return api.put({
        url: "result/update-ai-response/"+ id,
        body: {instructor_updated_evaluation:text,question_id:question_id},
      });
    } catch (error) {
      return new Error(error);
    }
  }
  searchUser(body) {
    try {
      return api.post({
        url: "result/quick-search",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResultById(resultId) {
    try {
      return api.get({
        url: "result/get-result/" + resultId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResultByQuizId(quiz_id) {
    try {
      return api.get({
        url: "result/get-result-by-quiz/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizResult(quizId) {
    try {
      return api.get({
        url: "quiz-report/get-quiz-results/" + quizId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getClassResult(session_id) {
    try {
      return api.get({
        url: "result/get-class-result/" + session_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getStudentQuizPerformance(quizId) {
    try {
      return api.get({
        url: "quiz-report/get-student-quiz-performance/" + quizId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getTopStudents(quizId) {
    try {
      return api.get({
        url: "quiz-report/get-top-students/" + quizId,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getResult(result_id) {
    try {
      return api.get({
        url: "result/get-result/" + result_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getGrade = (percentage) => {
    try {
      if (percentage >= 90) {
        return "A+";
      } else if (percentage < 90 && percentage >= 80) {
        return "A";
      } else if (percentage < 80 && percentage >= 70) {
        return "B";
      } else if (percentage < 70 && percentage >= 60) {
        return "C";
      } else if (percentage < 60 && percentage >= 50) {
        return "D";
      } else if (percentage < 50 && percentage >= 40) {
        return "E";
      } else if (percentage < 40) {
        return "F";
      }
      return "NA";
    } catch (error) {
      return "NA";
    }
  };
  getQuizResults(quiz_id) {
    try {
      return api.get({
        url: "quiz-report/get-quiz-results/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuestionsByQuizId(quiz_id) {
    try {
      return api.get({
        url: "question/get-question-by-quiz-id/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getStudentPerformanceTrend(student_id, result_id) {
    try {
      return api.get({
        url:
          "result/get-student-quiz-performance/" + student_id + "/" + result_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizTopStudents(id, rid, first) {
    try {
      let url = "quiz-report/get-top-students/" + id;
      if (first === true || first === false)
        url += "?attempt=" + (first ? "first" : "latest");
      if (!(first === true || first === false) && rid) url += "?result=" + rid;
      if ((first === true || first === false) && rid) url += "&result=" + rid;

      return api.get({
        url,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  uploadCsv(data) {
    try {
      return api.post({
        url: "quiz/upload-csv",
        body: data,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getFilteredAndSortQuizes(data, instructor_id) {
    try {
      return api.post({
        url: `quiz/get-filtered-and-sort-quizes/${instructor_id}`,
        body: data,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getTopicWiseStrength(session_id, result_id) {
    return result_id
      ? api.get({
          url: "result/get-topic-wise-strength/" + session_id + "/" + result_id,
        })
      : api.get({
          url: "result/get-topic-wise-strength/" + session_id,
        });
  }
  getToughestQuestion(quiz_id, session_id) {
    return session_id
      ? api.get({
          url: "result/get-toughest-questions/" + quiz_id + "/" + session_id,
        })
      : api.get({
          url: "result/get-toughest-questions/" + quiz_id,
        });
  }
  getQuizCityWiseStats = (quiz_id) => {
    return api.get({ url: "quiz-report/get-city-wise-stats/" + quiz_id });
  };
  async runQuizAI(quizSelectData) {
    try {
      return api.post({
        url: "question/get-solution-ai",
        body: quizSelectData,
      });
    } catch (error) {
      return new Error(error);
    }
  }
}
export default new QuizService();
