import api from "../services/api-service";

class SessionService {
  getAllSessions(instructor_id) {
    try {
      return api.get({
        url: "session/get-sessions-by-instructor-id/" + instructor_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  editSession(body) {
    try {
      return api.put({
        url: "session/edit-session",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizSessions(quiz_id) {
    try {
      return api.get({
        url: "session/get-sessions-by-quiz-id/" + quiz_id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  createSession(body) {
    try {
      return api.post({
        url: "session/create-session",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  updateSession(body) {
    try {
      return api.put({
        url: "session/update-session",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getGeneralSessionByQuizId(id) {
    try {
      return api.get({
        url: "session/get-general-session-by-quiz-id/" + id,
      });
    } catch (error) {
      return new Error(error);
    }
  }
}
export default new SessionService();
