import Vue from "vue";
import App from "./app-module/App.vue";
import "./registerServiceWorker";
import router from "./app-module/router/app-routes";
import store from "./app-module/store/app-store";
import moment from "moment";
import Toast from "vue-toastification";
import ScrollLoader from "vue-scroll-loader";
import Datetime from "vue-datetime"
import "vue-toastification/dist/index.css";
import "vue-datetime/dist/vue-datetime.css";
// import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 5,
  newestOnTop: true,
  pauseOnFocusLoss: true,
});

Vue.filter("formatDate", function(value) {
  if (value) {
    var stillUtc = moment.utc(value).toDate();
    return moment(stillUtc)
      .local()
      .format("L LT");
  }
});

Vue.use(ScrollLoader);
Vue.use(Datetime)

Vue.config.productionTip = false;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyARiLx6LYqnPBtxI6NDSweSS-hBIfzfYEA",
//     // libraries: "places" // necessary for places input
//   }
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
