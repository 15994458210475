<template src="./unauthorized-page.html"></template>
<script>
import storageService from "../../../quiz-module/services/storage-service";

export default {
  name: "unauthorized-page",
  async created() {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    eventer(
      messageEvent,
      (event) => {
        if (event.data && event.data.success) {
          storageService.set("uid", event.data.data.userId);
          storageService.set("authorization", event.data.data.token);
          storageService.set("user", event.data.data);
          this.$router.push("/");
          location.reload();
        }
      },
      false
    );
  },
  methods: {
    async createQuizPopupHandler() {
      NearpeerAuth("instructor");
    },
  },
  beforeDestroy() {
    var eventMethod = window.addEventListener
      ? "removeEventListener"
      : "detachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "detachEvent" ? "onmessage" : "message";
    eventer(messageEvent, (event) => {}, false);
  },
};
</script>

<style src="./unauthorized-page.css"></style>
