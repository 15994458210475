<template src="./single-question-stat.html"></template>
<script>
import Doughnut from "../../../shared-module/charts/Doughnut";

export default {
  name: "app-single-question-stat",
  props: [
    "questionNo",
    "question",
    "totalAttempts",
    "toThePoint",
    "questionWiseStats",
  ],
  components: {
    "app-Doughnut": Doughnut,
  },
  data: function () {
    return {
      updating: false,
      prev_question_no: null,
    };
  },
  methods: {
    close() {
      // this.$emit("buttonClick");
    },
    mathCreator: function (id, data) {
      let mathFieldSpan = document.getElementById(id);
      let MQ = MathQuill.getInterface(2);
      let mathField = MQ.MathField(mathFieldSpan, {
        spaceBehavesLikeTab: false, // configurable
      });
      // console.log("id->", id);
      // console.log("mathFieldSpan->", mathFieldSpan);
      // console.log("mathField->", mathField);
      // console.log("data->", data);
      if (data && !data.match(/\\\s/g)) {
        data = data.replace(/\s+/g, "\\ ");
      }
      if (data !== "" && data !== "null") {
        if ((mathFieldSpan, mathField)) {
          mathField.latex(data);
        }
      }
    },
  },
  mounted() {
    if (this.question.question_id) {
      this.question = _.merge(this.question, this.question.question_id);
      delete this.question.question_id;
      this.$forceUpdate();
    }
    this.question.options.forEach((option, index) => {
      if (option.equation) {
        this.mathCreator(
          `${this.questionNo}-${index}-equation-option`,
          option.equation
        );
      }
    });
    if (this.question.equation) {
      this.mathCreator(
        `${this.questionNo}-question-equation`,
        this.question.equation
      );
    }
    if (this.question.solution_equation) {
      this.mathCreator(
        `${this.questionNo}-solution-equation`,
        this.question.solution_equation
      );
    }
  },
  updated() {
    if (this.prev_question_no !== this.questionNo) {
      this.question.options.forEach((option, index) => {
        if (option.equation) {
          this.mathCreator(
            `${this.questionNo}-${index}-equation-option`,
            option.equation
          );
        }
      });
      if (this.question.equation) {
        this.mathCreator(
          `${this.questionNo}-question-equation`,
          this.question.equation
        );
      }
      this.prev_question_no = this.questionNo;
    }
  },
  watch: {
    questionNo(val, prevVal) {
      if (this.question.question_id) {
        this.question = _.merge(this.question, this.question.question_id);
        delete this.question.question_id;
        this.$forceUpdate();
      }
      if (val != prevVal) {
        this.prev_question_no = prevVal;
      }
    },
  },
};
</script>
<style src="./single-question-stat.css" scoped></style>
