<template src="./App.html"></template>

<script>
const axios = require("axios");

export default {
  name: "app",
  created() {
    this.getCompanyDetail();
  },
  methods: {
    async getCompanyDetail() {
      const res = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MAIN_BACKEND_URL}company-config/get`
      });
      if(res.data && res.data.success && res.data.company) {
        const company = res.data.company;
        this.$store.commit("setCompany", company);
        // customStorageService.setCompany(company);
        // if (company.activeInvoice && company.activeInvoice.status !== 'paid') {
        //   const lastDate = moment(company.activeInvoice.invoice_last_date).endOf('day');
        //   const currentDate = moment();
        //   const isPassed = lastDate.isBefore(currentDate);
        //   if (isPassed) {
        //     this.restrictUser = true;
        //   }
        // }
      }
    },
  }
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css";
@import "../styles/style.css";
</style>
