<script>
import { Doughnut } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Doughnut,
  props: ["value", "backgroundColor", "dataLables"],
  data: () => ({
    chartdata: {
      labels: ["A"],
      datasets: [
        {
          label: "Data One",
          data: [0],
        },
      ],
    },
    options: {
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          color: "#fff",
          borderRadius: 3,
          font: {
            size: 14,
          },
        },
      },
      legend: false,
      responsive: true,
      tooltips: {
        enabled: false,
      },
      cutoutPercentage: 80,
      // maintainAspectRatio: true
    },
  }),
  mounted() {
    if (this.dataLables) {
      this.addPlugin(ChartDataLabels);
      this.options.cutoutPercentage = 65
    }
    this.renderChart(this.chartdata, this.options);
    this.chartdata = {
      labels: ["Average"],
      datasets: [
        {
          label: "",
          backgroundColor: this.backgroundColor,
          data: this.value,
        },
      ],
    };
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    value: function (val) {
      this.value;
      this.chartdata = {
        labels: ["Average"],
        datasets: [
          {
            label: "",
            backgroundColor: this.backgroundColor,
            data: this.value,
          },
        ],
      };
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>

<style></style>
