<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["labels", "data"],
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
        {
          label: "Your Average",
          backgroundColor: "#5169D5",
          data: [],
          barThickness: 10,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    },
  }),
  mounted() {
    this.chartdata = {
      labels: this.labels,
      datasets: this.data,
    };
    this.renderChart(this.chartdata, this.options);
    console.log("data in bar: ", this.data);
    console.log("labels in bar", this.labels);
  },
  watch: {
    data: function (val) {
      this.chartdata = {
        labels: this.labels,
        datasets: this.data,
      };
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>

<style></style>
