import api from "../services/api-service";

class QuizService {
  getQuizResults(id, session_id) {
    try {
      let url = "/quiz-report/get-quiz-results/" + id;
      if (session_id) {
        url = url + "/" + session_id;
      }
      return api.get({
        url,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizTopStudents(id, session_id) {
    try {
      let url = "/quiz-report/get-top-students/" + id;
      if (session_id) {
        url = url + "/" + session_id;
      }
      return api.get({
        url,
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getQuizStats(id, session_id) {
    try {
      let url = "/quiz-report/quiz-stats/" + id;
      if (session_id) {
        url = url + "/" + session_id;
      }
      return api.get({
        url,
      });
    } catch (error) {
      return new Error(error);
    }
  }
}
export default new QuizService();
