import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    company: {},
    companyFeatures: [],
  },
  mutations: {
    setCompany(state, payload) {
      state.company = payload;
      state.companyFeatures = payload.features;
    },
  },
  actions: {},
  modules: {}
});
