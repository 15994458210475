import { io } from "socket.io-client";

export const saveQuestionSocketInit = (
  { questions, warnings, isPublished, isSkippable },
  newVersionCallback,
  onComplete,
  errorCallback,
  versionSavedCallback,
  progressEvent
) => {
  const socket = io(process.env.VUE_APP_SOCKET_URL, {
    path: "/quizservice/socket/save-question",
    transports: ["websocket"],
  });
  const questionsInner = [...questions];
  //   socket.disconnect()
  socket.connect();

  socket.on("connect", () => {
    console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  });

  socket.on("disconnect", () => {
    console.log(socket.id); // undefined
  });

  const createNewVersion = () => {
    socket.emit("createNewVersion");
  };
  socket.on("needToCreateNewVersion", () =>
    newVersionCallback(createNewVersion)
  );
  socket.on("error", () => errorCallback());
  socket.on("saved", ({ question, warnings }) => {
    progressEvent(100);
    onComplete();
    socket.disconnect();
  });
  socket.on("Vsaved", (data) => {
    versionSavedCallback(data);
    socket.disconnect();
  });
  socket.on("progress", (progress) => progressEvent(progress));

  let iterations = Math.ceil(questions.length / 100);
  for (let i = 1; i <= iterations; i++) {
    if (questionsInner.length >= 100) {
      socket.emit("questions", {
        questions: questionsInner.splice(0, 100),
        warnings,
        isPublished,
        isSkippable,
        progress: (i / iterations) * 50,
      });
    } else {
      socket.emit("questions", {
        questions: questionsInner.splice(0, questionsInner.length),
        warnings,
        isPublished,
        isSkippable,
        progress: (i / iterations) * 50,
      });
      //   socket.emit("complete");
    }
    if (questionsInner.length === 0) socket.emit("complete");
  }
};
