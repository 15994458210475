<template src="./stats-header.html"></template>
<script>
import reportService from "../../services/report-service";
import quizService from "../../services/quiz-service";
import sessionService from "./../../services/session-service";
var reportsArray = [
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
];
var reportsPopup = false;
export default {
  name: "app-stats-header",
  props: ["page", "quizId", "quiz", "reportCount"],
  data: () => {
    return {
      hide: false,
      menu: false,
      reportsArray,
      reportsPopup,
      session_id: "",
      quizID: "",
      sessions: "",
      report_count: "",
    };
  },
  async created() {
    let response = await quizService.getQuizById(this.quizId);
    this.quiz = response.data.data.quiz;
    this.report_count = response.data.data.quiz.reports_count;
    this.getQuizSessions();
  },
  mounted() {
    if (this.$route.name == "app-quiz-stats") {
      this.hide = true;
      this.menu = true;
    } else {
      this.hide = false;
      this.menu = false;
    }
    this.quizID = this.$route.params.quiz_id;
  },
  methods: {
    getQuizResults() {
      let selectSession = document.getElementById("sessionSelect").value;
      if (selectSession == "all") {
        selectSession = "";
      }
      this.$parent.getQuizResults(selectSession);
    },
    quizSummary() {
      this.$emit("clickEvent");
    },
    async getQuizSessions() {
      const res = await sessionService.getQuizSessions(this.quizID);
      this.sessions = res.data.data.sessions;
    },

    reportsPopupHandler(quiz) {
      // quiz = this.quiz;
      // this.getQuizReports(quiz);
      window.open(`${process.env.VUE_APP_REPORTING_PANEL}quiz-issues`, '_blank')
    },
    async getQuizReports(quiz) {
      let response = await quizService.getQuizById(this.quizId);
      this.reportsArray = await reportService.prepareReports(
        response.data.data.quiz
      );
      this.reportsPopup = true;
    },
    popupClose() {
      this.reportsPopup = false;
    },
  },
};
</script>
<style src="./stats-header.css" scoped></style>
