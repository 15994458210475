import api from "../services/api-service";

class VideoService {
  async getVideos(params) {
    try {
      return api.get({
        url: "cvideos/getVideosById",
        params,
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async getVideoPoints(id) {
    try {
      return api.get({
        url: "cvideos/points/" + id,
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async saveVideoPoints(body) {
    try {
      return api.post({
        url: "cvideos/points/",
        body,
        server: "main",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  async updateVideoPoints(body){
    try{
      return api.post({
        url:"cvideos/changeQuizVideoPoint",
        body,
        server: "main",
      });
    } catch(error){
      return new Error(error);
    }
  }
}
export default new VideoService();
