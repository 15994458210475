import {
  mainBackend,
  quizBackendService,
  authBackend
} from "../../../environment/environment";
import storageService from "../services/storage-service";

var headers = {
  uid: storageService.get("uid"),
  Authorization: storageService.get("authorization"),
};

class ApiService {
  get(data) {
    if (data.server === "main") {
      return mainBackend.request({
        headers,
        method: "get",
        url: data.url,
        params: data.params
      });
    } else {
      return quizBackendService.request({
        headers,
        method: "get",
        url: data.url,
        params: data.params
      });
    }
  }
  post(data) {
    if (data.server === "main") {
      return mainBackend.request({
        headers,
        method: "post",
        url: data.url,
        data: data.body
      });
    } else if (data.server === "auth") {
      return authBackend.request({
        headers,
        method: "post",
        url: data.url,
        data: data.body
      });
    } else {
      return quizBackendService.request({
        headers,
        method: "post",
        url: data.url,
        data: data.body,
        onUploadProgress: data.config
      });
    }
  }
  put(data) {
    if (data.server === "main") {
      return mainBackend.request({
        headers,
        method: "put",
        url: data.url,
        data: data.body
      });
    } else {
      return quizBackendService.request({
        headers,
        method: "put",
        url: data.url,
        data: data.body
      });
    }
  }
  delete(data) {
    if (data.server === "main") {
      return mainBackend.request({
        headers,
        method: "delete",
        url: data.url
      });
    } else {
      return quizBackendService.request({
        headers,
        method: "delete",
        url: data.url
      });
    }
  }
}
export default new ApiService();
