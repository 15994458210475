import csv from "csvtojson";
import { io } from "socket.io-client";
// const socket = io("http://localhost:3002/csv-socket");
// import tagService from "./tag-service";
import TagService from "./tag-service";
export const readQuestionFromCSV = async (
  string,
  progressCallback1,
  progressCallback2
) => {
  return new Promise((resolve, reject) => {
    let err;
    const warnings = [];
    const questions = []; // Array to store Question Objects
    const sections = [];
    let mergedTags = [];
    /**
     * temp scv config
     * {
        // headers: true,
        // trim: true,
        // checkColumn: true,
        // includeColumns: /(ind_number|question|image_url|equation|tags|difficulty_level|weightage|section|paragaph|related_video_id|solution|solution_equation|solution_image_url|option1|equation1|image_url1|is_correct1|option2|equation2|image_url2|is_correct2|option3|equation3|image_url3|is_correct3|option4|equation4|image_url4|is_correct4)/
    }
     */
    csv()
      .fromString(string)
      .then((json) => {
        if (!json.length)
          return reject(new Error("CSV must contains at least one record"));
        json.forEach((rowfromCSV, row_index) => {
          const row = rowfromCSV;
          if (
            row.equation === undefined ||
            row.equation === "" ||
            row.equation === " "
          ) {
            if (
              row.question === undefined ||
              row.question === "" ||
              row.question === " "
            ) {
              warnings.push(
                `error # Question text can not be empty at Question # ${row.ind_number}`
              );
            }
          } else {
            row.question = "";
          }
          if (row.tags) {
            row.tags = row.tags.split("+");
            row.tags.forEach((tag) => {
              if (tag.trim())
                mergedTags.push({ tag_type: "general", tag_name: tag.trim() });
            });
          } else {
            row.tags = [];
          }
          sections.push(row.section);
          let index = 1;
          const options = [];
          Object.keys(row).forEach((key) => {
            row.options = options;
            const pos = index - 1;
            if (key === `option${index}`) {
              row.options.push({ option: row[key] });
            }
            if (key === `equation${index}`) {
              row.options[pos].equation = row[key];
            }
            if (key === `image_url${index}`) {
              row.options[pos].image_url = row[key];
            }
            if (key === `is_correct${index}`) {
              if (row[key] === "TRUE") {
                row.options[pos].is_correct = true;
              } else {
                row.options[pos].is_correct = false;
              }
              index += 1;
            }
          });
          let optionstrueCount = 0;
          row.options.forEach((option, i) => {
            if (
              option.equation === undefined ||
              option.equation === "" ||
              option.equation === " "
            ) {
              if (
                option.option === undefined ||
                option.option === "" ||
                option.option === " "
              ) {
                warnings.push(
                  `error # Option ${i + 1} text/equation must not be empty # ${
                    row.ind_number
                  }`
                );
              }
            } else {
              row.options[i].option = "";
            }
            if (
              option.option === "" &&
              option.equation === "" &&
              option.image_url === "" &&
              option.is_correct === ""
            ) {
              row.options.splice(i, 1);
            }
            if (option.is_correct) {
              optionstrueCount += 1;
            }
          });
          if (optionstrueCount === 0 || optionstrueCount > 1) {
            warnings.push(
              `error # Minimum one correct option is required # ${row.ind_number}`
            );
          }
          if (row.options.length < 2) {
            warnings.push(
              `error # Minimum two options are required # ${row.ind_number}`
            );
          }
          if (row.weightage) {
            row.self_assigned_weightage = true;
          } else {
            delete row.weightage;
            row.self_assigned_weightage = false;
          }
          if (
            row.solution_equation !== undefined &&
            row.solution_equation !== "" &&
            row.solution_equation !== " "
          ) {
            row.solution = "";
          }
          progressCallback1(json.length, row_index + 1);
          questions.push(row);
        });
      })
      .then(() => {
        mergedTags = [
          ...Array.from(
            new Set(mergedTags.map((a) => a.tag_name))
          ).map((tag_name) => mergedTags.find((a) => a.tag_name == tag_name)),
        ];
        TagService.saveTags({
          tags: mergedTags,
        }).then((res) => {
          questions.forEach((question, index) => {
            const temp_tags = [];
            question.tags.forEach((tag) => {
              if (tag.trim()) {
                let index = res.data.data.tags.findIndex(
                  (t) => t.tag_name.trim() === tag.trim()
                );
                temp_tags.push(res.data.data.tags[index]);
              }
            });
            questions[index].tags = [...temp_tags];
            progressCallback2(questions.length, index + 1);
          });
          resolve({ sections, questions, warnings });
        });
      })
      .catch((error) => reject(new Error(error)));
  });
};

export const readFileContent = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      reader.onload = function(e) {
        var theBytes = e.target.result;
        resolve(theBytes);
      };
      reader.readAsText(file);
    } catch (error) {
      reject(new Error(errorp));
    }
  });
};

export const csvSocketinit = (
  file,
  quizId,
  porgressCallback,
  onComplete,
  errorCallback
) => {
  const socket = io(process.env.VUE_APP_SOCKET_URL, {
    path: "/quizservice/socket/csv-upload",
    transports: ["websocket"],
  });
  let questionsAll = [];
  //   socket.disconnect()
  socket.connect();

  socket.on("connect", () => {
    console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  });

  socket.on("disconnect", () => {
    console.log(socket.id); // undefined
  });

  socket.on("question", ({ questions, progress }) => {
    questionsAll = [...questionsAll, ...questions];
    porgressCallback(progress);
  });
  socket.on("error", (error) => {
    errorCallback(error);
  });

  socket.on("complete", ({ sections, warnings }) => {
    questionsAll = [
      ...questionsAll.sort((a, b) => a.ind_number - b.ind_number),
    ];
    onComplete({ sections, warnings, questions: [...questionsAll] });
    4;
    socket.disconnect();
    questionsAll = [];
  });
  socket.emit("file", { file, quizId });
};

export const exportToCSV = (rows, fileName) => {
  let csvContent =rows.map((e) => e.join(",")).join("\n");

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, 'data.csv');
};
