import api from "../services/api-service";
import quizService from "../services/quiz-service";

class ReportService {
  async prepareReports(quiz) {
    let reportsArray = [
      { totalCount: 0, questions: [] },
      { totalCount: 0, questions: [] },
      { totalCount: 0, questions: [] },
      { totalCount: 0, questions: [] },
      { totalCount: 0, questions: [] },
    ];
    try{
        if (quiz.reports) {
            const quiz_reports = quiz.reports;
            let index;
            quiz_reports.forEach((repo) => {
              repo.report_desc.forEach((reportDesc) => {
                if (reportDesc == "Topic in lecture heading is not covered") {
                  index = 0;
                } else if (reportDesc == "Content Improvement") {
                  index = 1;
                } else if (reportDesc == "Question is unclear") {
                  index = 2;
                } else if (reportDesc == "Options are unclear or repeated") {
                  index = 3;
                } else {
                  index = 4;
                }
                reportsArray[index].desc = reportDesc;
                reportsArray[index].totalCount++;
                if (reportsArray[index].questions) {
                  if (!reportsArray[index].questions.includes(repo.question_no)) {
                    reportsArray[index].questions.push(repo.question_no);
                  }
                } else {
                  reportsArray[index].questions.push(repo.question_no);
                }
              });
            });
            reportsArray.quiz = quiz;
            let response = await quizService.getQuestionsByQuizId(quiz._id);
            reportsArray.questions = response.data.data.questions;
            reportsArray.flag = true;
            return reportsArray;
          }
    } catch (error){
        return new Error(error);
    }
    
  }
}
export default new ReportService();
