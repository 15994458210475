<template src="./quiz-landing.html"></template>
<script>
import appHeader from "./../../../shared-module/components/app-header/app-header.vue";
import createQuiz from "./../create-quiz/create-quiz";
import addParagraph from "./../edit-quiz-questions/add-paragraph/add-paragraph";
import quizService from "../../services/quiz-service";
import reportService from "../../services/report-service";
import storageService from "../../services/storage-service";
import ClickOutside from "vue-click-outside";
import { exportToCSV } from "../../services/csvService";
var chevronListBox;
var createQuizPopup = false;
var reportsPopup = false;
var sort_By = "created_at";
var order_By = false;
var reportsArray = [
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
  { totalCount: 0, questions: [] },
];
var quizzes;
var archivedView = false;
var versionHistory = false;
export default {
  name: "app-quiz-landing",
  components: {
    "app-create-quiz": createQuiz,
    "app-add-paragraph": addParagraph,
    "app-header": appHeader,
  },
  directives: {
    ClickOutside,
  },
  data: () => {
    return {
      publish_unpublish_check: 0,
      check_archive: 0,
      chevronListBox,
      reportsPopup,
      reportsArray,
      createQuizPopup,
      sort_By,
      order_By,
      quizzes: [],
      archivedView,
      pageNo: 1,
      // pageRange: [],
      versionHistory,
      searchFlag: true,
      searchOtherFlag: false,
      quizName: "",
      searchResults: "",
      courseName: "",
      moduleName: "",
      quizDiv: "",
      currentQuiz: 0,
      quizId: "",
      // currPage: 1,
      quizData: "",
      currentVersion: 0,
      isArchived: false,
      selectedQuizzes: [],
      selectionFlag: false,
      condition: "",
      versions: "",
      nextPage: false,
      previousPage: false,
      pages: "",
      page: "",
      nextPageNo: "",
      countUnPublishQuiz: "",
      countQuiz: "",
      hasPrevpage: false,
      previousPageNo: "",
      loadMore: false,
      dataFetched: false,
      isFirst: true,
      isNext: true,
      val: "",
      isfullQuiz: false,
      versionID: "",
      qType: "",
      isQuiz_loading: false,
      filterAndSort: {
        filters: {
          is_publish: true,
          is_archived: false,
          quiz_type: "all",
        },
        sort: {
          sortBy: "created_at",
          sortOrder: -1,
        },
        search: {
          key: "",
          value: "",
          search: false,
        },
        pagination: {
          page: 1,
          perPage: 50,
        },
      },
    };
  },
  async created() {
    const instructorId = storageService.get("uid");
    if(instructorId) {
      this.getUnPublishedCount();
      this.getPublishedCount();
      // this.filterAndSortQuiz();
    }
    
    if (this.$route.query.editType && this.$route.query.editType === "back") {
      let quizEdit = await quizService.getQuizById(
        this.$route.query.EditquizID
      );
      this.createQuizPopupHandler(quizEdit.data.data.quiz);
    }
  },
  methods: {
    async filterToggle(toggle, value) {
      if(toggle == 'is_publish' && value == false){
         this.publish_unpublish_check = 1;
      }
      if(toggle == 'is_publish' && value == true){
        this.publish_unpublish_check = 0;
      }
      this.isQuiz_loading = true;
      this.filterAndSort.filters[toggle] = value;
      // this.currPage = 1;
      this.filterAndSortQuiz(true);
    },
    async sortToggle(toggle) {
      this.isQuiz_loading = true;
      if (this.filterAndSort.sort.sortBy === toggle)
        this.filterAndSort.sort.sortOrder =
          this.filterAndSort.sort.sortOrder === 1 ? -1 : 1;
      else {
        this.filterAndSort.sort.sortBy = toggle;
        this.filterAndSort.sort.sortOrder = -1;
      }
      this.filterAndSortQuiz(true);
    },
    async filterAndSortQuiz(first) {
      try {
        if(this.filterAndSort.filters.is_archived == false){
          this.check_archive = 0;
        }
        else{
          this.check_archive = 1;
        }
        const instructorId = storageService.get("uid");

        if (first) this.filterAndSort.pagination.page = 1;
        const response = await quizService.getFilteredAndSortQuizes(
          this.filterAndSort,
          instructorId
        );
        this.isQuiz_loading = false;
        if (response) {
          this.filterAndSort.pagination.page++;
          this.isNext = response.data.hasNextPage;
          if (first) this.quizzes = [];
          this.quizzes.push(...response.data.docs);
          // this.nextPage = response.data.data.quizzes.hasNextPage;
          // this.previousPage = response.data.data.quizzes.hasPrevPage;
          // this.pages = response.data.totalPages;
          // this.pageRange = [];
          // for (var i = 1; i <= this.pages; i++) {
          //   this.pageRange.push(i);
          // }
          // this.currentPage = response.data.page;
          // this.nextPageNo = response.data.data.quizzes.nextPage;
          // this.previousPageNo = response.data.data.quizzes.prevPage;
        }
      } catch (error) {
        // this.$toast.error("Something went wrong");
        this.isNext = false;
        this.isQuiz_loading = false;
        console.error(error);
      }
    },
    async DuplicateQuiz(id) {
      try {
        let quiz_id = id;
        let resp;
        resp = await quizService.duplicateQuiz(quiz_id);
        if (quiz_id) {
          this.closePopUp();
          this.$toast("Quiz Duplicated");
          this.$router.go();
        }
      } catch (error) {
        this.$toast.error("Error Quiz Not Duplicated");
      }
    },
    async getPublishedCount() {
      try {
        const instructorId = storageService.get("uid");
        let response = await quizService.getPublishedCount(instructorId);
        this.countQuiz = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getUnPublishedCount() {
      try {
        const instructorId = storageService.get("uid");
        let response = await quizService.getUnPublishedCount(instructorId);
        this.countUnPublishQuiz = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async exportCsv() {
      try {
        let data = { quiz_id: this.selectedQuizzes };
        if (this.selectedQuizzes == "") {
          this.$toast("plz select a quiz first");
        }
        data.quiz_id.forEach(async (val) => {
          var idd = "";
          idd = val;
          let resp;
          resp = await quizService.downloadCsv(idd);
          if (resp.data.status === "success") {
            exportToCSV(resp.data.data.csv, idd);
          }
        });
      } catch (error) {
        this.$toast.error("Error while file download");
      }
    },
    async downloadCsvFile(id) {
      try {
        let quiz_id = id;
        let resp;
        resp = await quizService.downloadCsv(quiz_id);
        if (resp.data.status === "success") {
          exportToCSV(resp.data.data.csv, id);
        }
      } catch (error) {
        this.$toast.error("Error in file download");
      }
    },
    async getShareableLink(id, quiz, type) {
      try {
        let quiz_id = id;
        let res;
        let link;
        if (type === "pop") {
          link = `${process.env.VUE_APP_SHARE_ABLE_LINK_POP}course/${quiz.module.index_url}/${quiz.video.module_no}/${quiz.video.id}?courseIndexUrl=${quiz.course.index_url}`;
        } else {
          res = await quizService.getShareableLink(quiz_id);
          link =
            process.env.VUE_APP_SHARE_ABLE_LINK + res.data.data.session._id;
        }
        navigator.clipboard.writeText(link);
        if (link) {
          this.closePopUp();
          this.$toast("Link Coppied");
        }
      } catch (error) {
        this.$toast.error("Error Link Not Coppied");
      }
    },
    async onScroll() {
      if (this.isNext) this.filterAndSortQuiz();
      // if (this.isNext) {
      //   if (this.condition === "GetPublish") {
      //     this.getpublishQuizzes(false, this.sort_By, this.order_By);
      //   } else if (this.condition === "getunpublish") {
      //     this.getUnpublishQuizzes(false, this.sort_By, this.order_By);
      //   } else if (this.condition === "quizbytype") {
      //     this.QuizType(false, this.sort_By, this.order_By);
      //   } else if (this.condition === "getquizzes") {
      //     this.getQuizzes(false, this.sort_By, this.order_By);
      //   } else {
      //     this.getpublishQuizzes(false, this.sort_By, this.order_By);
      //   }
      // }
    },
    async sortt(condition, sort_By, order_By) {
      if (this.val === "GetPublish") {
        this.getpublishQuizzes(condition, sort_By, order_By);
      } else if (this.val === "getunpublish") {
        this.getUnpublishQuizzes(condition, sort_By, order_By);
      } else if (this.val === "getquizzes") {
        this.getQuizzes(condition, sort_By, order_By);
      } else {
        this.getpublishQuizzes(condition, sort_By, order_By);
      }
    },
    async QuizType(isFirst) {
      this.condition = "quizbytype";
      var test = document.getElementById("select").value;
      if (test == "SELECT") {
        this.getpublishQuizzes(true, this.sort_By, this.order_By);
      } else {
        const instructorId = storageService.get("uid");
        if (isFirst) {
          this.currPage = 1;
        }
        let data = {
          type: test,
          instructor_id: instructorId,
          page: this.currPage,
          perPage: 50,
        };
        let response;
        response = await quizService.getQuizByType(data);
        if (response) {
          this.pageNo++;
          if (!response.data.hasNextPage) {
            this.isNext = false;
          }
          if (isFirst) {
            this.isNext = !response.data.hasNextPage ? false : true;
            this.quizzes = response.data.docs;
          } else {
            this.quizzes.push(...response.data.docs);
          }
          // this.nextPage = response.data.data.quizzes.hasNextPage;
          // this.previousPage = response.data.data.quizzes.hasPrevPage;
          // this.pages = response.data.data.quizzes.totalPages;
          // this.pageRange = [];
          // for (var i = 1; i <= this.pages; i++) {
          //   this.pageRange.push(i);
          // }
          // this.currentPage = response.data.data.quizzes.page;
          // this.currPage = pageNo;
          // this.nextPageNo = response.data.data.quizzes.nextPage;
          // this.previousPageNo = response.data.data.quizzes.prevPage;
          // this.pages++;
        }
      }
    },
    async getpublishQuizzes(isFirst, sort, order) {
      this.val = "GetPublish";
      this.condition = "GetPublish";
      if (isFirst) {
        this.currPage = 1;
      }
      const instructorId = storageService.get("uid");
      let response;
      let data = {
        instructor_id: instructorId,
        page: this.currPage,
        perPage: 50,
      };
      this.order_By = order;
      if (sort != undefined) {
        this.condition = "GetPublish";
        this.sort_By = sort;
        data = {
          instructor_id: instructorId,
          page: this.currPage,
          perPage: 50,
          sortBy: sort,
          orderBy: order,
        };
      }
      response = await quizService.getPublishQuiz(data);
      if (response) {
        this.pageNo++;
        if (!response.data.hasNextPage) {
          this.isNext = false;
        }
        if (isFirst) {
          this.isNext = !response.data.hasNextPage ? false : true;
          this.quizzes = response.data.docs;
        } else {
          this.quizzes.push(...response.data.docs);
        }
        // this.nextPage = response.data.data.quizzes.hasNextPage;
        // this.previousPage = response.data.data.quizzes.hasPrevPage;
        // this.pages = response.data.totalPages;
        // this.pageRange = [];
        // for (var i = 1; i <= this.pages; i++) {
        //   this.pageRange.push(i);
        // }
        // this.currentPage = response.data.page;
        // this.currPage = pageNo;
        // this.nextPageNo = response.data.data.quizzes.nextPage;
        // this.previousPageNo = response.data.data.quizzes.prevPage;
        // this.pages++;
      }
    },
    async getUnpublishQuizzes(isFirst, sort, order) {
      this.val = "getunpublish";
      this.condition = "getunpublish";
      if (isFirst) {
        this.isNext = true;
        this.currPage = 1;
      }
      const instructorId = storageService.get("uid");
      let response;
      let data = {
        instructor_id: instructorId,
        page: this.currPage,
        perPage: 50,
      };
      this.order_By = order;
      if (sort != undefined) {
        this.condition = "getunpublish";
        this.sort_By = sort;
        data = {
          instructor_id: instructorId,
          page: this.currPage,
          perPage: 50,
          sortBy: sort,
          orderBy: order,
        };
      }

      response = await quizService.getUnPublishQuiz(data);
      if (response) {
        this.pageNo++;
        if (!response.data.hasNextPage) {
          this.isNext = false;
        }
        if (isFirst) {
          this.quizzes = response.data.docs;
        } else {
          this.quizzes.push(...response.data.docs);
        }
        // this.nextPage = response.data.data.quizzes.hasNextPage;
        // this.previousPage = response.data.data.quizzes.hasPrevPage;
        // this.pages = response.data.data.quizzes.totalPages;
        // this.pageRange = [];
        // for (var i = 1; i <= this.pages; i++) {
        //   this.pageRange.push(i);
        // }
        // this.currentPage = response.data.data.quizzes.page;
        // this.nextPageNo = response.data.data.quizzes.nextPage;
        // this.previousPageNo = response.data.data.quizzes.prevPage;
        // this.pages++;
      }
    },
    getQuizzesPaginate(page) {
      this.currPage = page;
      this.filterAndSort.pagination.page = this.currPage;
      this.filterAndSortQuiz();
      this.pagesToShow();
    },
    pagesToShow() {
      let first = 0, last = 7, mid = 3;
      const total = this.pageRange.length;
      if(total > 7) {
        if(this.currPage > 4 && this.currPage < total - 2) {
          first = this.currPage - 4;
          last = this.currPage + 3;
        } else if (this.currPage > total - 3) {
          first = total - last;
          last = total;
        }
      }
      
      const pages = this.pageRange.slice(first, last);
      return pages;
    },
    async getQuizzes(isFirst, sort, order) {
      this.condition = "getquizzes";
      this.val = "getquizzes";
      const instructorId = storageService.get("uid");
      if (isFirst) {
        this.currPage = 1;
      }
      let response;
      let data = {
        instructor_id: instructorId,
        page: this.currPage,
        perPage: 50,
      };
      this.order_By = order;
      if (sort != undefined) {
        this.condition = "getquizzes";
        this.sort_By = sort;
        data = {
          instructor_id: instructorId,
          page: this.currPage,
          perPage: 50,
          sortBy: sort,
          orderBy: order,
        };
      }
      if (this.archivedView) {
        try {
          response = await quizService.getArchivedQuizzesByInstructorId(data);
          if (response) {
            if (!response.data.data.quizzes.hasNextPage) {
              this.isNext = false;
            }
            if (isFirst) {
              this.quizzes = response.data.data.quizzes.docs;
            } else {
              this.quizzes.push(...response.data.data.quizzes.docs);
            }
            // this.pages = response.data.data.quizzes.totalPages;
            // this.pageRange = [];
            // for (var i = 1; i <= this.pages; i++) {
            //   this.pageRange.push(i);
            // }
            // this.currentPage = response.data.data.quizzes.page;
          }
        } catch (error) {
          this.quizzes = [];
        }
      } else {
        response = await quizService.getQuizzesByInstructorId(data);
        if (response) {
          if (!response.data.data.quizzes.hasNextPage) {
            this.isNext = false;
          }
          if (isFirst) {
            this.isNext = !response.data.data.quizzes.hasNextPage
              ? false
              : true;
            this.quizzes = response.data.data.quizzes.docs;
          } else {
            this.quizzes.push(...response.data.data.quizzes.docs);
          }

          this.nextPage = response.data.data.quizzes.hasNextPage;
          this.previousPage = response.data.data.quizzes.hasPrevPage;
          this.pages = response.data.data.quizzes.totalPages;
          // this.pageRange = [];
          // for (var j = 1; j <= this.pages; j++) {
          //   this.pageRange.push(j);
          // }
          this.currentPage = response.data.data.quizzes.page;
          // this.currPage = pageNo;
          this.nextPageNo = response.data.data.quizzes.nextPage;
          this.previousPageNo = response.data.data.quizzes.prevPage;
          this.pages++;
        }
      }
      this.pageNo++;
    },

    myFunction(id) {
      this.quizId = id;
      this.chevronListBoxHandler(this.quizId);
    },
    chevronListBoxHandler(id) {
      this.quizId = id;
      this.currentQuiz = id;
      this.chevronListBox = !this.chevronListBox;
    },
    closePopUp() {
      this.chevronListBox = false;
      this.versionHistory = false;
    },
    versionShow(id) {
      this.versions = "";
      this.quizId = id;
      this.versionBoxHandler(this.quizId);
      this.getVersions(this.quizId);
    },
    versionBoxHandler(id) {
      this.currentVersion = id;
      this.versionHistory = !versionHistory;
    },
    async reportsPopupHandler(quiz) {
      // this.reportsArray = [
      //   { totalCount: 0, questions: [] },
      //   { totalCount: 0, questions: [] },
      //   { totalCount: 0, questions: [] },
      //   { totalCount: 0, questions: [] },
      //   { totalCount: 0, questions: [] },
      // ];
      // if (quiz.reports && quiz.reports.length) {
      //   this.reportsArray = await reportService.prepareReports(quiz);
      // }
      // this.reportsPopup = true;
      window.open(`${process.env.VUE_APP_REPORTING_PANEL}quiz-issues`, '_blank')
    },
    createQuizPopupHandler(quiz) {
      this.quizData = quiz;
      this.createQuizPopup = true;
    },
    popupClose() {
      // this.getQuizzes(false)
      this.reportsPopup = false;
      this.createQuizPopup = false;
    },
    popCpTop(){
      this.$refs.popCpTop.scrollTop = 0;
    },
    archiveUnarchive(isFirst) {
      this.archivedView = !this.archivedView;
      this.getQuizzes(isFirst);
    },
    getSearchFlag(args) {
      this.isQuiz_loading = true;
      if (args == 1) {
        this.searchFlag = false;
        this.searchOtherFlag = true;
      } else {
        this.searchFlag = true;
        this.searchOtherFlag = false;
        this.quizName = "";
        this.courseName = "";
        this.moduleName = "";
      }
      this.filterAndSort.search.search = false;
      this.filterAndSortQuiz();
    },
    async searchQuizzes(type) {
      this.isQuiz_loading = true;
      if (type == "quiz") {
        this.filterAndSort.search.value = this.quizName;
      } else if (type == "course") {
        this.filterAndSort.search.value = this.courseName;
      } else if (type == "module") {
        this.filterAndSort.search.value = this.moduleName;
      }
      if (!this.filterAndSort.search.value.trim()) {
        this.isQuiz_loading = false;
        return false;
      }
      this.filterAndSort.search.key = type;
      this.filterAndSort.search.search = true;
      this.filterAndSortQuiz(true);
    },

    async unpublishQuiz(flag, id) {
      try {
        if (flag) {
          let data = { quiz_id: [id] };
          let response = await quizService.unpublishQuiz(data);
          if (response) {
            this.closePopUp();
            this.$toast.success("Quiz unpublished");
          }
        } else {
          let data = { quiz_id: this.selectedQuizzes };
          let response = await quizService.unpublishQuiz(data);
          if (response) {
            this.$toast.success("Quiz unpublished");
          }
        }
      } catch (error) {
        this.$toast.error("Please select a quiz");
      }
      this.selectedQuizzes = [];
      this.getpublishQuizzes(true);
    },

    async archiveQuizid(id) {
      this.selectedQuizzes = [id.toString()];
      this.archiveQuiz();
    },
    async archiveQuiz(val) {
      try {
        if (val === true) {
          let data = { quiz_id: this.selectedQuizzes };
          let response = await quizService.archiveQuiz(data);
          if (response) {
            this.closePopUp();
            this.$toast.success("Archived Selected Quiz");
          }
        } else {
          let data = { quiz_id: this.selectedQuizzes };
          let response = await quizService.unarchiveQuiz(data);
          if (response) {
            this.closePopUp();
            this.$toast.success("Unarchived Selected Quiz");
          }
        }
      } catch (error) {
        this.$toast.error("Please select a quiz");
      }
      this.selectedQuizzes = [];
      this.getQuizzes(true);
    },
    selectAll() {
      this.selectedQuizzes = [];
      if (!this.selectionFlag) {
        if (this.quizName || this.courseName || this.moduleName) {
          for (let i in this.searchResults) {
            this.selectedQuizzes.push(this.searchResults[i].id);
          }
        } else {
          for (let i in this.quizzes) {
            this.selectedQuizzes.push(this.quizzes[i].id);
          }
        }
      }
    },
    async getVersions(id) {
      try {
        if (id) {
          let body = { quiz_id: id };
          let response = await quizService.getVersion(body);
          if (response.data.data.versionHistory) {
            this.versions = response.data.data.versionHistory;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    pageNext(incomingPage) {
      this.pageNo = incomingPage;
      this.getQuizzes();
    },
    async runAI(model){
      if(this.selectedQuizzes.length < 1) {
        return this.$toast.error("Please select quiz first");
      }
      let quizSelectData = { 
        "quizIds": this.selectedQuizzes,
        "modalType": model,
        "open_ai_params": {
            "temperature": 1,
            "top_p": 1
        }
      };
      this.$toast("AI Job started");
      try {
        const res = await quizService.runQuizAI(quizSelectData);
        if(res.data && res.data.success) {
          this.$toast.success("AI Job Completed successfully");
        } else {
          this.$toast.error("AI Job Failed");
        }
      } catch (error) {
        this.$toast.error("AI Job Error " + error.message);
      }
      
    },
    practiceQuiz(){
      this.$router.push("/practice-quiz");
    }
  },
};
</script>

<style src="./quiz-landing.css" scoped></style>
