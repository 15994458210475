import api from "../services/api-service";

class TagService {
  getAllTags() {
    try {
      return api.get({
        url: "tag/get-tags/general",
      });
    } catch (error) {
      return new Error(error);
    }
  }
  getAllNicheTags() {
    try {
      return api.get({
        url: "coursetag/get",
        server: "main"
      });
    } catch (error) {
      return new Error(error);
    }
  }
  saveTags(body) {
    try {
      return api.post({
        url: "tag/add-tags",
        body,
      });
    } catch (error) {
      return new Error(error);
    }
  }
}
export default new TagService();
