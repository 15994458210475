<template>
<div class="ellipsis">
<!-- <button @click="logout(null)" title="Logout" class="logout">
<img style="width: 20px;" src="../../assets/exit.svg" />
</button> -->
<div>
<router-view />
</template>

<script>
import storageService from "../quiz-module/services/storage-service";

export default {
  name: "app-quiz",
  props: {
    msg: String,
  },
  mounted(){
    console.log("mounted");
    // saveAISolutionSocketInit(
    //   () => {console.log("complete")},
    //   () => {console.log("error")},
    //   (progress) => {this.$toast.success(progress);console.log("progress", progress)}
    // )
  },
  methods: {
    logout() {
      storageService.set("uid", "");
      storageService.set("user", "");
      this.$router.push("/unauthorized-page");
      location.reload();
    },
  },
};
</script>
<style scoped>
.logout {
  position: fixed;
  right: 10px;
  top: 10px;
  background-color: white;
  border: none;
}
</style>
