<template src="./add-paragraph.html"> </template>
<script>
export default {
  name: "app-add-paragraph",
  data: function() {
    return {};
  },
  methods: {
    chevronListBoxHandler() {
      this.$emit("buttonClick");
    }
  }
};
</script>

<style src="./add-paragraph.css" scoped></style>
