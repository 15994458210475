import Vue from "vue";
import VueRouter from "vue-router";
import api from "../quiz-module/services/api-service";
import { AppPageNotFound, UnAuthorizedPage } from "../shared-module/components";
import { quizRoutes } from "../quiz-module/components";
import storageService from "../../app-module/quiz-module/services/storage-service";

Vue.use(VueRouter);

const appRoutes = [
  {
    path: "",
    redirect: "/quiz"
  },
  {
    path: "/unauthorized-page",
    name: "unauthorized-page",
    component: UnAuthorizedPage
  },
  {
    path: "/page-not-found",
    name: "page-not-found",
    component: AppPageNotFound
  }
];
const routes = [...quizRoutes, ...appRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let toPath = to;
  const paramKeys = Object.keys(to.params);
  if (paramKeys && paramKeys.length > 0) {
    toPath = toPath.path.split("/");
    paramKeys.forEach((key) => {
      toPath = toPath.map((path) => {
        if (path == to.params[key]) {
          return ":" + key;
        }
        return path;
      });
    });
    toPath = toPath.join("/");
  } else {
    toPath = toPath.path;
  }
  if(toPath == ':questions/quiz/edit-quiz-questions'){
    toPath = '/quiz/edit-quiz-questions';
  }

  next();
  try {
    let userId = await storageService.get("uid");
    if (userId) {
      const res = await api.post({
        server: "auth",
        url: "v1/auth/authenticate-frontend-route",
        body: {
          path: toPath,
          user_id: userId
        }
      });
      if (!res || !res.data.success) {
        next({ path: "/unauthorized-page" });
        
      }
    } else {
      next({ path: "/unauthorized-page" });
      
    }
  } catch (error) {
    next({ path: "/unauthorized-page" });
  
  }
});

export default router;
