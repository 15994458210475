<template src="./individual-results.html"></template>

<script>
//old
// import Doughnut from "./../../../../shared-module/charts/Doughnut";
// new
import QuizService from "../../../services/quiz-service";
import quizReportService from "../../../services/quiz-report-service";
import appHeader from "./../../../../shared-module/components/app-header/app-header.vue";
// import appsidebar from "./../../../../shared-module/components/app-sidebar/app-sidebar.vue";
import Bar from "./../../../../shared-module/charts/Bar";
import HorizontalBar from "./../../../../shared-module/charts/HorizontalBar";
import SingleQuestionStat from "../../single-question-stat/single-question-stat";
import _ from "lodash";

export default {
  name: "app-individual-results",
  data() {
    return {
      pageType: 1,
      isPageLoading: true,
      resultId: this.$route.query.resultId,
      result: {},
      totalAttempts: 0,
      ReviewallQuestions: false,
      grade: "NA",
      isPass: false,
      classQuizAverage: 0,
      question: {},
      questionNo: 1,
      questionsStats: [],
      topStudents: [],
      performanceTrendGraphData: [],
      trendGraphLabels: [],
      tagsGraphData: [],
      classTagsGraphLabels: [],
      suggestedVideos: [],
      allOptions: {},
      quizName: "",
      username: "",
      userInstitute: "Nearpeer",
      studentStats: {
        correct: 0,
        incorrect: 0,
        skipped: 0,
        avgPerQuestion: 0,
        totalTime: 0,
      },
      classAverageStats: {
        correct: 0,
        incorrect: 0,
        skipped: 0,
        avgPerQuestion: 0,
        totalTime: 0,
      },
      bestPerformanceAverageStats: {
        correct: 0,
        incorrect: 0,
        skipped: 0,
        avgPerQuestion: 0,
        totalTime: 0,
      },
    };
  },
  components: {
    "app-bars": Bar,
    "app-horizontal-bars": HorizontalBar,
    "app-header": appHeader,
    // "app-sidebar": appsidebar,
    "app-single-question-stat": SingleQuestionStat,
  },
  created() {
    this.getResult();
  },
  mounted() {},
  methods: {
    ReviewallQuestionsToggle() {
      this.ReviewallQuestions = !this.ReviewallQuestions;
    },
    selectQuestion(question, index) {
      this.question = question;
      this.ReviewallQuestions = false;
      this.questionNo = index + 1;
    },
    async getResult() {
      let [result, classResult] = await Promise.all([QuizService.getResult(this.resultId), QuizService.getClassResult(this.resultId)]);
      this.result = result.data.data.result;
      this.quizName = this.result.quiz.name;
      this.username = this.result.user.full_name;
      this.userInstitute = this.result.user.university;

      const questionStats = result.data.data.questionStats;
      this.totalAttempts = result.data.data.attemptCount;
      this.result.questions.forEach((question) => {
        _.findLast(questionStats.questionCorrectCount, (correctQuestion) => {
          if (correctQuestion._id == question.question_id._id) {
            question.correctCount = correctQuestion.correct_count;
            question.averageTime = correctQuestion.averageTime;
            question.bestTime = correctQuestion.bestTime;
          }
        });
        _.findLast(questionStats.questionMarkedCount, (markedQuestion) => {
          if (markedQuestion._id == question.question_id._id) {
            question.markedCount = markedQuestion.markedCount;
          }
        });
        _.findLast(questionStats.questionSkippedCount, (skipQuestion) => {
          if (skipQuestion._id == question.question_id._id) {
            question.skippedCount = skipQuestion.skippedCount;
          }
        });
      });

      // let classResult = await QuizService.getClassResult(
      //   this.result.session_id
      // );
      classResult = classResult.data.data;
      this.classAverageStats = {
        correct:
          (classResult.questionStats.questionCorrectCount[0]?.correctCount || 0) /
          classResult.attemptCount,
        incorrect:
          (classResult.questionStats.questionIncorrectCount[0]?.incorrectCount || 0) /
          classResult.attemptCount,
        skipped:
          (classResult.questionStats.questionSkippedCount[0]?.skippedCount || 0) /
          classResult.attemptCount,
        totalTime: this.formatTime(
          classResult.questionStats.averageTimePerQuiz[0]?.averageTime
        ),
        avgTimePerQuestion:
          this.formatTime(classResult.questionStats.averageTimePerQuestion[0]?.averageTime || 0),
      };

      
      this.calculateStats("current_student");
      this.grade = QuizService.getGrade(this.result.correct_percentage);
      this.isPass =
        this.result.correct_percentage >= this.result.quiz.passing_percentage;
      this.question = this.result.questions[0];
      this.questionsStats = this.result.questions;
      this.getQuizTopStudents();
      this.getStudentPerformanceTrend();
      // this.getQuizGraphData();
      this.calculateTagsGraphData();
      this.isPageLoading = false;
    },
    async getQuizTopStudents() {
      try {
        let response = await QuizService.getQuizTopStudents(
          this.result.quiz_id
        );

        if (response) {
          this.topStudents = response.data.data.quiz;
          this.calculateStats("best_performer");
          this.topStudents.forEach((student, index) => {
            student.time_spent = this.formatTime(student.time_spent);
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getStudentPerformanceTrend() {
      try {
        let response = await QuizService.getStudentPerformanceTrend(
          this.result.user_id,
          this.resultId
        );
        if (response) {
          const stats = response.data.statsData;
          console.log(stats);
          this.classQuizAverage = stats.classAveragePercentage;
          const classTrendGraphData = [];
          const studentTrendGraphData = [];
          const performanceTrendGraphData = [];
          const trendGraphLabels = [];
          stats.classAverageScore.forEach((classAverage, index) => {
            const isFound = stats.yourAverageScore.find(
              (yourAverage) =>
                yourAverage.name.trim() === classAverage.name.trim()
            );
            classTrendGraphData.push(classAverage.average);
            if (isFound) {
              studentTrendGraphData.push(isFound.average);
            } else {
              studentTrendGraphData.push(0);
            }
            trendGraphLabels.push(classAverage.name);
          });
          this.trendGraphLabels = trendGraphLabels;

          performanceTrendGraphData.push({
            label: "Class Average",
            backgroundColor: "#FCE4B4",
            data: classTrendGraphData,
            barThickness: 10,
          });
          performanceTrendGraphData.push({
            label: "Your Average",
            backgroundColor: "#5169D5",
            data: studentTrendGraphData,
            barThickness: 10,
          });

          this.performanceTrendGraphData = performanceTrendGraphData;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getQuizGraphData() {
      this.calculateTagsGraphData();
      const res = await QuizService.getQuizResults(this.result.quiz_id);
      if (res.data.data && res.data.status === "success") {
        const quizAllResults = res.data.data.quizResults;
        this.totalAttempts = quizAllResults.length;
        this.calculateStats("class_average", quizAllResults);
      }
    },
    calculateStats(type) {
      //Current Studate Average
      if (type === "current_student") {
        this.studentStats.skipped = this.result.skipped_count;
        this.studentStats.totalTime = this.formatTime(this.result.time_spent);
        this.studentStats.avgTimePerQuestion = this.result.questions.length
          ? this.formatTime(
              this.result.time_spent / this.result.questions.length
            )
          : "0s";
        let studentCorrect = 0;
        let studentIncorrect = 0;
        this.result.questions.forEach((question, index) => {
          if (question.result) {
            studentCorrect += 1;
          } else {
            studentIncorrect += 1;

            if (question.related_video) {
              this.suggestedVideos.push(question.related_video);
            }
          }
          question.skipped = this.result.skipped_questions.includes(
            question._id
          );
        });
        this.studentStats.correct = studentCorrect;
        this.studentStats.incorrect = studentIncorrect;
      } else if (type === "best_performer") {
        //Best Performance Average
        this.bestPerformanceAverageStats.skipped =
          this.topStudents[0].skipped_count;
        this.bestPerformanceAverageStats.totalTime = this.formatTime(
          this.topStudents[0].time_spent
        );
        this.bestPerformanceAverageStats.avgTimePerQuestion = this.formatTime(
          this.topStudents[0].time_spent / this.result.questions.length
        );
        this.bestPerformanceAverageStats.correct =
          this.topStudents[0].correct_count;
        this.bestPerformanceAverageStats.incorrect =
          this.topStudents[0].incorrect_count;
      }
    },
    async calculateTagsGraphData() {
      try {
        const res = await QuizService.getTopicWiseStrength(
          this.result.session_id,
          this.result._id
        );
        if (res.data && res.data.status === "success") {
          const youAverageGraphData = res.data.classAverageStats.map(
            a =>
              res.data.resultStats.find(b => a.name === b.name)
                ?.score_percentage || 0
          );
          let tagsGraphData = [];
          tagsGraphData.push({
            label: "Class Average",
            backgroundColor: "#7BC0F7",
            data: res.data.classAverageStats.map(a => a.score_percentage),
            barThickness: 10
          });
          tagsGraphData.push({
            label: "Your Average",
            backgroundColor: "#5169D5",
            data: youAverageGraphData,
            barThickness: 10
          });
          this.classTagsGraphLabels = res.data.classAverageStats.map(
            a => a.name
          );
          this.tagsGraphData = tagsGraphData;
        }
      } catch (err) {
        console.log(err);
      }
    },
    formatTime(sec) {
     try {
        let avgTime = "";
        sec = parseInt(sec);
        sec = sec.toFixed(0);
        sec = parseInt(sec);
        let time = new Date(sec * 1000).toISOString().substr(11, 8);
        time = time.split(":");
        if (parseInt(time[0]) > 0) {
          avgTime = time[0] + "h ";
        }
        if (parseInt(time[1]) > 0) {
          avgTime = avgTime + time[1] + "m ";
        }
        if (parseInt(time[2]) > 0) {
          avgTime = avgTime + time[2] + "s";
        }
        return avgTime;
      } catch (error) {
        console.log(error);
        return "0m";
      }
    },
    backToMainResults () {
      console.log('working');
      window.history.back();
      console.log('working funtion');
    }
  },
};
</script>

<style src="./individual-results.css" scoped></style>
